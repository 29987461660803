import api from "../../services/api";
import * as constants from "./constants";

export const fetchActivities = (params) => async (dispatch) => {
  try {
    const { data: activities } = await api.get("/activities/", { params });
    dispatch({
      type: constants.LOAD_ALL_PARAM_SUCCESS,
      payload: activities,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: constants.LOAD_ALL_PARAM_FAILURE,
      payload: params,
    });
  }
};

export const createActivity = (values) => async (dispatch) => {
  try {
    const { data: postedData } = await api.post("/activities/", values);
    dispatch({
      type: constants.CREATE_ITEM_SUCCESS,
      payload: postedData,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: constants.CREATE_ITEM_FAILURE,
      payload: values,
    });
  }
};

export const editActivity = (values) => async (dispatch) => {
  try {
    const { data: patchedData } = await api.patch(
      `/activities/${values.id}/`,
      values
    );
    dispatch({
      type: constants.EDIT_ITEM_SUCCESS,
      payload: patchedData,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: constants.EDIT_ITEM_FAILURE,
      payload: values,
    });
  }
};

export const deleteActivity = (id) => async (dispatch) => {
  try {
    await api.delete(`/activities/${id}/`);
    dispatch({
      type: constants.DELETE_ITEM_SUCCESS,
      payload: id,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: constants.DELETE_ITEM_FAILURE,
      payload: id,
    });
  }
};
