import React, { useEffect } from "react";
import { useUser } from "../context/UserContext";

// export function withAwaitAuth(Route) {
//   return ({ ...props }) => {
//     const { loading } = useAuth();

//     if (!loading) return <Route {...props} />;
//     return null;
//   };
// }

export function withRoleRoute(Route, roles = []) {
  return ({ ...props }) => {
    const [user] = useUser();
    const canAccess = roles.includes(user.role);
    if (!canAccess) window.location.replace("/");

    if (canAccess) return <Route {...props} />;
    return null;
  };
}

// export default function withPrivateRoute(Route) {
//   return ({ ...props }) => {
//     const { user, isAuthenticated, loading, loginUrl } = useAuth();
//     useEffect(() => {
//       if (!isAuthenticated && !loading) window.location.replace(loginUrl);
//     }, [loading, isAuthenticated]);

//     if (isAuthenticated && user) return <Route {...props} />;
//     return null;
//   };
// }