import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import styled from "styled-components";
import "moment/locale/pt-br";

import EditableCheckin from "../EditableCheckin";
import WorkflowAction from "../../workflows/WorkflowAction";
import { useDispatch } from "react-redux";
import { createCheckin } from "../../../redux/checkins/actions";
import InputField from "../../common/forms/InputField";

moment.locale("pt-br");

export const PerfilDiv = styled(Link)`
  width: 36px;
  height: 36px;
  background-color: #a6b3f7;
  border-radius: 8px;
  font-size: 20px;
  color: #313c6d;
`;

function CreateNewCheckin({ extraParams }) {
  const dispatch = useDispatch();

  const handleEnter = (e) => {
    if (e.keyCode !== 13 || e.target.value.length === 0) return;
    dispatch(
      createCheckin({
        title: e.target.value,
        ...extraParams,
      })
    );
    e.target.value = "";
    e.target.blur();
  };

  return (
    <div className="d:f a-i:c">
      <InputField
        placeholder={"Crie um novo item e aperte enter ou ↩"}
        onKeyUp={handleEnter}
        maxLength={120}
      />
    </div>
  );
}

export default function PersonalCheckins({
  data,
  date,
  createFields,
  workflows,
  minified = false,
}) {
  return (
    <div>
      <ul>
        {workflows?.map((item) => (
          <li className="m-b:.6" key={`checkin-workflow-${item.id}`}>
            <WorkflowAction data={item} />
          </li>
        ))}
      </ul>
      <ul>
        {data?.map((item) => (
          <li className="m-b:.6" key={`PersonalCheckins-li-item-${item.id}`}>
            <EditableCheckin data={item} date={date} minified={minified} />
          </li>
        ))}
      </ul>
      {createFields && <CreateNewCheckin extraParams={createFields} />}
    </div>
  );
}
