import React, { useState } from "react";
import { Dropdown, Menu, Popover } from "antd";

import Icon from "./Icon";
import Button from "./Button";
import TagPopover from "../tags/TagPopover";
import CalendarPopover from "./CalendarPopover";
import AddShortcutModal from "../integrations/AddShortcutModal";
import styled from "styled-components";

const Item = styled(Menu.Item)`
  i:hover {
    color: blue;
  }
`;
export default function ActionsMenu({
  reduced,
  onDelete,
  onUpdate,
  data,
  dataType,
  mutate = () => {},
}) {
  const [popoverVisible, setPopoverVisible] = useState();
  const [integrationsVisible, setIntegrationsVisible] = useState(false);

  const handleClickMenu = ({ key }) => {
    switch (key) {
      case "delete":
        onDelete();
        break;
      case "tags":
        setPopoverVisible(
          <TagPopover
            value={data.tags.map(({ id }) => id)}
            onChange={onUpdate}
          />
        );
        break;
      case "calendar":
        setPopoverVisible(
          <CalendarPopover onUpdate={onUpdate} date={data.date} />
        );
        break;
      case "integrations":
        setIntegrationsVisible(true);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <AddShortcutModal
        visible={integrationsVisible}
        onHide={() => setIntegrationsVisible(false)}
        onSuccess={() => {
          dataType === "CHECKIN" ? onUpdate({}) : mutate();
          setIntegrationsVisible(false);
        }}
        type={dataType}
        obj_id={data.id}
      />
      <Popover
        trigger="click"
        overlayStyle={{ zIndex: 10 }}
        visible={!!popoverVisible}
        content={popoverVisible}
        onVisibleChange={(v) => !v && setPopoverVisible(v)}
      >
        <Dropdown
          placement="topCenter"
          overlay={
            <Menu className="d:f" onClick={handleClickMenu}>
              {!reduced && (
                <>
                  <Item key="tags">
                    <Icon type="tag" />
                  </Item>
                  {(dataType === "CHECKIN" || dataType === "ACTIVITY") && (
                    <Item key="calendar">
                      <Icon type="month" />
                    </Item>
                  )}
                  {dataType !== "ACTIVITY" && (
                    <Item key="integrations">
                      <Icon type="integrations" />
                    </Item>
                  )}
                </>
              )}
              <Item key="delete">
                <Icon type="delete" />
              </Item>
            </Menu>
          }
          trigger="click"
        >
          <Button type="text" icon="dotsVertical" />
        </Dropdown>
      </Popover>
    </>
  );
}
