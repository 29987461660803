// @flow
import {
  FETCH_TASKS,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILED,
  FETCH_TASK,
  PATCH_TASK,
  PATCH_TASK_SUCCESS,
  PATCH_TASK_FAILED,
  CREATE_TASK,
  DELETE_TASK,
  DELETE_TASK_FAILED,
  DELETE_TASK_SUCCESS,
} from "./constants";
import { mergeDistinct } from "../../utils";

const INIT_STATE = {
  tasks: [],
  loaded: false,
  loading: false,
};

const Tasks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_TASKS:
      return { ...state, loading: true, loaded: false };
    case FETCH_TASKS_SUCCESS:
      return {
        ...state,
        tasks: mergeDistinct(state.tasks, action.payload, "id"),
        loading: false,
        error: null,
        loaded: true,
      };
    case FETCH_TASKS_FAILED:
      return { ...state, error: action.payload, loading: false, loaded: true };

    case FETCH_TASK:
      return { ...state, loading: true };

    case PATCH_TASK:
      return { ...state, loading: true };
    case PATCH_TASK_SUCCESS:
      const tasks = [...state.tasks];
      const index = tasks.findIndex(({ id }) => id == action.payload.id);
      tasks[index] = action.payload;
      return { ...state, tasks };
    case PATCH_TASK_FAILED:
      return { ...state };

    case DELETE_TASK:
      return { ...state, loading: true };
    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        tasks: state.tasks.filter(({ id }) => id != action.payload),
      };
    case DELETE_TASK_FAILED:
      return { ...state, error: action.payload, loading: false };

    case CREATE_TASK:
      return { ...state, loading: true };

    default:
      return { ...state };
  }
};

export default Tasks;
