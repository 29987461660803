import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";
import amplitude from "amplitude-js";
import { Amplitude } from "@amplitude/react-amplitude";

import DashboardView from "../views/common/DashboardView";

import routes from "../lib/routes";
import NotificationsProvider from "../providers/NotificationsProvider";
import { UserContext } from "../context/UserContext";

function NoMatchRoute() {
  return <Redirect to="/focos-da-semana" />;
}

class DashboardRouter extends React.Component {
  static contextType = UserContext;

  render() {
    const inTrial = this.context.user.company.plan_type == "FREE_TRIAL";
    const daysTrial = this.context.user.company.trial_remaining_time;
    const renderRoutes = (
      <Switch>
        {routes
          .filter((route) => !route.fullPage)
          .map((route) => (
            <Route {...route} key={route.path} />
          ))}
        <Route component={NoMatchRoute} />
      </Switch>
    );
    return (
      <Amplitude
        eventProperties={{
          company: this.context.user.company.id,
          member: this.context.user.id,
        }}
      >
        {/* <NotificationsProvider> */}
        {this.context.user.id && (
          <DashboardView
            routes={renderRoutes}
            inTrial={inTrial}
            daysTrial={daysTrial}
          />
        )}
        {/* </NotificationsProvider> */}
      </Amplitude>
    );
  }
}

export default DashboardRouter;
