import React, { useState } from "react";
import { Form, Popover } from "antd";
import styled from "styled-components";

export default function PopoverForm({
  trigger = "click",
  children,
  title,
  content,
  onFinish,
  initialValues,
}) {
  const [visible, setVisible] = useState(false);
  return (
    <StyledPopover
      onVisibleChange={setVisible}
      visible={visible}
      title={
        <h5 className="text-darkviolet m-b:0 f-w:400" style={{ fontSize: 16 }}>
          {title}
        </h5>
      }
      trigger={trigger}
      content={
        <Form
          initialValues={initialValues}
          style={{ width: 300 }}
          onFinish={(...v) => {
            onFinish(...v);
            setVisible(false);
          }}
        >
          <div className="p:.8">{content}</div>
          <div className="footer-btn-wrapper">
            <button
              className="btn btn-secondary"
              onClick={() => setVisible(false)}
            >
              {"Cancelar"}
            </button>
            <button className="btn btn-primary" htmlType="submit">
              Confirmar
            </button>
          </div>
        </Form>
      }
    >
      {children}
    </StyledPopover>
  );
}
const StyledPopover = styled(({ className, ...props }) => (
  <Popover overlayClassName={className} {...props} />
))`
  .ant-popover-title {
    background: var(--neutralblue);
    padding: 9px 15px !important;
  }
  .ant-popover-inner {
    overflow: hidden;
    border-radius: var(--border-radius);
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  .footer-btn-wrapper > * {
    width: 50%;
    border-radius: 0;
  }
`;
