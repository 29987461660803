import React from "react";
import moment from "moment";
import useSWR from "swr";

import "moment/locale/pt-br";

import Icon from "../common/Icon";
import Card from "../common/Card";
import api, { get } from "../../services/api";
import InputField from "../common/forms/InputField";
import { Loader } from "../common/PageLoader";
import PriorityItem from "../weekly-priorities/PriorityItem";
import { displayGenericError } from "../../utils/messages";
import { useUser } from "../../context/UserContext";

moment.locale("pt-br");

export function CreateNewPriority({
  url = "/weekly_priorities/",
  extraParams,
  onCreate,
}) {
  const handleEnter = async (e) => {
    if (e.keyCode !== 13 || e.target.value.length === 0) return;
    try {
      const { data } = await api.post(url, {
        title: e.target.value,
        ...extraParams,
      });
      onCreate(data);
      e.target.value = "";
      e.target.blur();
    } catch {
      displayGenericError();
    }
  };

  return (
    <div className="d:f a-i:c">
      <InputField
        placeholder="Escreva o próximo item e aperte enter"
        onKeyUp={handleEnter}
      />
    </div>
  );
}

function WeekCard({
  title,
  weekStart,
  extraParams,
  creationEnabled = true,
  minDate = false,
  maxDate = false,
  ...props
}) {
  const date = weekStart.clone();
  const params = new URLSearchParams({
    ...extraParams,
    week_init_date: date.format("DD/MM/YYYY"),
  });
  const { data, error, mutate } = useSWR(
    `/weekly_priorities/?${params.toString()}`,
    get,
    {
      revalidateOnFocus: false,
    }
  );
  const loading = !(data || error);
  const handleUpdateItem = (item, shouldRemove = false) => {
    let newData = [...data];
    if (shouldRemove) newData = newData.filter(({ id }) => id !== item);
    else {
      const index = newData.findIndex(({ id }) => item.id === id);
      if (index > -1) newData[index] = item;
      else newData.push(item);
    }
    mutate(newData, false);
  };

  return (
    <div {...props}>
      {title && (
        <div className="d:f a-i:c m-b:.8">
          <h6 className="text-primary t-t:u m-b:0">{title}</h6>
        </div>
      )}
      <Card>
        <h5 className="d:i-b m-b:1">Semana {date.get("week")} ·</h5>{" "}
        <span>
          {date.clone().startOf("week").format("DD MMMM")} a{" "}
          {date.clone().endOf("week").format("DD MMMM")}
        </span>
        {loading ? (
          <div className="t-a:c p-y:9">
            <Loader />
          </div>
        ) : data && data.length > 0 ? (
          <ul>
            {data.map((item, i) => (
              <li className="m-b:1" key={item.id}>
                <PriorityItem
                  index={i + 1}
                  data={item}
                  mutatePriorityItems={mutate}
                  onUpdate={handleUpdateItem}
                  reduced={true}
                />
              </li>
            ))}
          </ul>
        ) : (
          !creationEnabled && (
            <div className="p-y:1 t-a:c f:5 text-gray">
              <Icon type="empty" />
            </div>
          )
        )}
        {creationEnabled && (
          <CreateNewPriority
            createUrl="/focos-da-semana/criar"
            extraParams={{
              ...extraParams,
              week_init_date: date.format("YYYY-MM-DD"),
            }}
            onCreate={handleUpdateItem}
          />
        )}
      </Card>
    </div>
  );
}

export default function WeeklyPriorities({ user }) {
  const date = moment();
  const extraParams = { type: "MEMBER" };
  if (user) extraParams.member = user.member_id;

  return (
    <WeekCard
      weekStart={date.clone().startOf("week")}
      creationEnabled={date.isBefore(date.clone().endOf("week"))}
      extraParams={extraParams}
      className="m-b:5"
    />
  );
}
