import { LOCATION_CHANGE } from "connected-react-router";
import { matchPath } from "react-router-dom";

import { modalRoutes } from "../../lib/routes";

const INIT_STATE = {
  lastRegularLocation: {
    pathname: "/objetivos",
  },
  isRegular: true,
};

const Location = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      const lastRegularLocation = action.payload.location;
      const isRegular = !modalRoutes.reduce(
        (prev, route) =>
          prev ? prev : matchPath(lastRegularLocation.pathname, route),
        false
      );
      if (isRegular) return { ...state, lastRegularLocation, isRegular };
      else return { ...state, isRegular };

    default:
      return { ...state };
  }
};

export default Location;
