import styled from "styled-components";

export const StyledDiv = styled.div`
  background: linear-gradient(to bottom, #7389f3 0%, #7db9e8 100%);
  height: 100vh;
  display: flex;
  flex-direction: column;
   label {
    font-weight: 500;
    font-size: 25px;
    color: black;
    text-transform: none;
    text-align: center;
  }
  img {
    filter: invert(100%) sepia(103%) saturate(0%) hue-rotate(93deg)
      brightness(103%) contrast(103%);
    margin: 0.5em 2em 0.5em 1em;
    width: 93px;
    height: 36px;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-form-item-label {
    display: flex;
    justify-content: center;
  }
  .ant-form-item-control {
    margin: 2em 0;
  }
  .stepDiv {
    max-width: 600px;
  }
  .header {
    background-color: white;
    position: absolute;
    width: 100%;
    color: #313c6d;
    font-size: 20px;
    align-items: center;
    display: flex;
    span {
      font-weight: 500;
    }
    img {
      filter: unset;
    }
  }
`;
