export const FETCH_GOALS = "FETCH_GOALS";
export const FETCH_GOALS_SUCCESS = "FETCH_GOALS_SUCCESS";
export const FETCH_GOALS_FAILED = "FETCH_GOALS_FAILED";

export const FETCH_GOAL = "FETCH_GOAL";
export const FETCH_GOAL_SUCCESS = "FETCH_GOAL_SUCCESS";
export const FETCH_GOAL_FAILED = "FETCH_GOAL_FAILED";

export const SET_COMPANY_GOALS = "SET_COMPANY_GOALS";

export const PATCH_GOAL = "PATCH_GOAL";
export const PATCH_GOAL_SUCCESS = "PATCH_GOAL_SUCCESS";
export const PATCH_GOAL_FAILED = "PATCH_GOAL_FAILED";

export const CREATE_GOAL = "CREATE_GOAL";

export const DELETE_GOAL = "DELETE_GOAL";
export const DELETE_GOAL_SUCCESS = "DELETE_GOAL_SUCCESS";
export const DELETE_GOAL_FAILED = "DELETE_GOAL_FAILED";
