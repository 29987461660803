import React from "react";
import classnames from "classnames";

import Textarea from "react-expanding-textarea";

const classNames = require("classnames");

const InputField = ({
  labelTitle,
  type = "text",
  id,
  error,
  required = false,
  className,
  ...inputProps
}) => {
  const field = (
    <>
      {type === "textarea" ? (
        <Textarea
          id={id}
          {...inputProps}
          className={classnames("form-control", labelTitle && className)}
        />
      ) : (
        <input
          id={id}
          type={type}
          {...inputProps}
          className={`${
            type === "checkbox" ? "form-control-checkbox" : "form-control"
          }`}
        />
      )}
      {error && (
        <small className="text-danger">
          {Array.isArray(error) ? error[0] : error}
        </small>
      )}
    </>
  );
  return labelTitle ? (
    <div className={classnames("form-group", className)}>
      <label htmlFor={id} className={classNames(required && "label-required")}>
        {labelTitle}
      </label>
      {field}
    </div>
  ) : (
    field
  );
};

export default InputField;
