import produce from "immer";

// displayGenericError();
// message.error("Parece que houve um erro, tente novamente mais tarde!");

import * as constants from "./constants";

const initialState = {
  entities: {},
};

const Activities = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case constants.LOAD_ALL_PARAM_SUCCESS:
        draft.entities = {
          ...draft.entities,
          ...action.payload.reduce((nextState, activity) => {
            nextState[activity.id] = activity;
            return nextState;
          }, {}),
        };
        break;

      case constants.CREATE_ITEM_SUCCESS:
        draft.entities[action.payload.id] = action.payload;
        break;

      case constants.EDIT_ITEM_SUCCESS:
        draft.entities[action.payload.id] = action.payload;
        break;

      case constants.DELETE_ITEM_SUCCESS:
        delete draft.entities[action.payload];
        break;

      default:
        return state;
    }
  });

export default Activities;
