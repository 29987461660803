import {
  FETCH_KEYRESULTS,
  FETCH_KEYRESULTS_SUCCESS,
  FETCH_KEYRESULTS_FAILED,
  FETCH_KEYRESULT,
  PATCH_KEYRESULT,
  PATCH_KEYRESULT_SUCCESS,
  PATCH_KEYRESULT_FAILED,
  CREATE_KEYRESULT,
  DELETE_KEYRESULT,
  DELETE_KEYRESULT_FAILED,
  DELETE_KEYRESULT_SUCCESS,
} from "./constants";

export const fetchKeyResults = (params) => ({
  type: FETCH_KEYRESULTS,
  payload: params,
});

export const fetchKeyResultsSuccess = (goals) => ({
  type: FETCH_KEYRESULTS_SUCCESS,
  payload: goals,
});

export const fetchKeyResultssFailed = (error) => ({
  type: FETCH_KEYRESULTS_FAILED,
  payload: error,
});

export const fetchKeyResult = (params) => ({
  type: FETCH_KEYRESULT,
  payload: params,
});

export const patchKeyResults = (id, data) => ({
  type: PATCH_KEYRESULT,
  payload: { id, data },
});

export const patchKeyResultsSuccess = (KeyResults) => ({
  type: PATCH_KEYRESULT_SUCCESS,
  payload: KeyResults,
});

export const patchKeyResultsFailed = (error) => ({
  type: PATCH_KEYRESULT_FAILED,
  payload: error,
});

export const createKeyResults = (data) => ({
  type: CREATE_KEYRESULT,
  payload: data,
});

export const deleteKeyResults = (id) => ({
  type: DELETE_KEYRESULT,
  payload: id,
});

export const deleteKeyResultsSuccess = (id) => ({
  type: DELETE_KEYRESULT_FAILED,
  payload: id,
});

export const deleteKeyResultsFailed = (error) => ({
  type: DELETE_KEYRESULT_SUCCESS,
  payload: error,
});
