import React from "react";
import styled from "styled-components";

const StyledLabel = styled.label`
  color: var(--primary);
  font-weight: 500;
  margin-bottom: 8px;
  font-size: ${(props) => (props.size ? props.size : "13px")};
  display: block;
`;

export default function Label({ icon: Icon, children, title, ...props }) {
  return (
    <StyledLabel {...props}>
      {Icon && <Icon className="feather-icon mr-1" />}
      {children || title}
    </StyledLabel>
  );
}
