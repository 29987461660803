import api from "../../services/api";
import * as constants from "./constants";

export const fetchCheckins = (params) => async (dispatch) => {
  try {
    const { data: checkins } = await api.get("/checkins/", { params });
    dispatch({
      type: constants.LOAD_ALL_PARAM_SUCCESS,
      payload: checkins,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: constants.LOAD_ALL_PARAM_FAILURE,
      payload: params,
    });
  }
};

export const createCheckin = (values) => async (dispatch) => {
  try {
    const { data: postedData } = await api.post("/checkins/", values);
    dispatch({
      type: constants.CREATE_ITEM_SUCCESS,
      payload: postedData,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: constants.CREATE_ITEM_FAILURE,
      payload: values,
    });
  }
};

export const editCheckin = (values) => async (dispatch) => {
  try {
    const { data: patchedData } = await api.patch(
      `/checkins/${values.id}/`,
      values
    );
    dispatch({
      type: constants.EDIT_ITEM_SUCCESS,
      payload: patchedData,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: constants.EDIT_ITEM_FAILURE,
      payload: values,
    });
  }
};

export const deleteCheckin = (id) => async (dispatch) => {
  try {
    await api.delete(`/checkins/${id}/`);
    dispatch({
      type: constants.DELETE_ITEM_SUCCESS,
      payload: id,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: constants.DELETE_ITEM_FAILURE,
      payload: id,
    });
  }
};
