import React from "react";

import Icon from "../common/Icon";

export default function Header({ headerKey }) {
  const options = {
    1: {
      text: "Foco da Semana",
      icon: "focus",
    },
    2: {
      text: "Checkins",
      icon: "checkin",
    },
  };
  return (
    <div className={headerKey ? "header" : 'pos:a'}>
      <img src="../../logo.png" />
      {headerKey && (
        <span>
          <Icon type={options[headerKey].icon} className="m-r:.5" />
          {options[headerKey].text}
        </span>
      )}
    </div>
  );
}
