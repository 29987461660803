export const LOAD_ALL_PARAM_SUCCESS = "WORKFLOWS:LOAD_ALL_PARAM_SUCCESS";
export const LOAD_ALL_PARAM_FAILURE = "WORKFLOWS:LOAD_ALL_PARAM_FAILURE";

export const LOAD_OCURRENCES_PARAM_SUCCESS =
  "WORKFLOWS:LOAD_OCURRENCES_PARAM_SUCCESS";
export const LOAD_OCURRENCES_PARAM_FAILURE =
  "WORKFLOWS:LOAD_OCURRENCES_PARAM_FAILURE";

export const LOAD_ITEM_SUCCESS = "WORKFLOWS:LOAD_ITEM_SUCCESS";
export const LOAD_ITEM_FAILURE = "WORKFLOWS:LOAD_ITEM_FAILURE";

export const CREATE_ITEM_SUCCESS = "WORKFLOWS:CREATE_ITEM_SUCCESS";
export const CREATE_ITEM_FAILURE = "WORKFLOWS:CREATE_ITEM_FAILURE";

export const DELETE_ITEM_SUCCESS = "WORKFLOWS:DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAILURE = "WORKFLOWS:DELETE_ITEM_FAILURE";
