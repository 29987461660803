// @flow
import {
  FETCH_KEYRESULTS,
  FETCH_KEYRESULTS_SUCCESS,
  FETCH_KEYRESULTS_FAILED,
  FETCH_KEYRESULT,
  PATCH_KEYRESULT,
  PATCH_KEYRESULT_SUCCESS,
  PATCH_KEYRESULT_FAILED,
  CREATE_KEYRESULT,
  DELETE_KEYRESULT,
  DELETE_KEYRESULT_FAILED,
  DELETE_KEYRESULT_SUCCESS,
} from "./constants";
import { mergeDistinct } from "../../utils";

const INIT_STATE = {
  keyresults: [],
  loaded: false,
  loading: false,
};

const KeyResults = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_KEYRESULTS:
      return { ...state, loading: true, loaded: false };
    case FETCH_KEYRESULTS_SUCCESS:
      return {
        ...state,
        keyresults: mergeDistinct(state.keyresults, action.payload, "id"),
        loading: false,
        error: null,
        loaded: true,
      };
    case FETCH_KEYRESULTS_FAILED:
      return { ...state, error: action.payload, loading: false, loaded: true };

    case FETCH_KEYRESULT:
      return { ...state, loading: true, loaded: false };

    case PATCH_KEYRESULT:
      return { ...state, loading: true };
    case PATCH_KEYRESULT_SUCCESS:
      const keyresults = [...state.keyresults];
      const index = keyresults.findIndex(({ id }) => id == action.payload.id);
      keyresults[index] = action.payload;
      return { ...state, keyresults };
    case PATCH_KEYRESULT_FAILED:
      return { ...state };

    case CREATE_KEYRESULT:
      return { ...state, loading: true };

    case DELETE_KEYRESULT:
      return { ...state, loading: true, loaded: false };
    case DELETE_KEYRESULT_FAILED:
      return {
        ...state,
        goals: state.keyresults.filter(({ id }) => id != action.payload),
      };
    case DELETE_KEYRESULT_SUCCESS:
      return { ...state, error: action.payload, loading: false, loaded: true };

    default:
      return { ...state };
  }
};

export default KeyResults;
