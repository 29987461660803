// @flow
import { all, fork, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import { push } from "connected-react-router";

import {
  FETCH_IDEAS,
  FETCH_IDEA,
  PATCH_IDEA,
  CREATE_IDEA,
  DELETE_IDEA,
} from "./constants";

import {
  fetchIdeasFailed,
  fetchIdeasSuccess,
  patchIdeaFailed,
  patchIdeaSuccess,
  deleteIdeaSuccess,
  deleteIdeaFailed,
} from "./actions";

const url = "/api/ideias/";

function* ideas({ payload }) {
  try {
    const { data } = yield Axios.get(url, {
      params: payload,
    });
    yield put(fetchIdeasSuccess(data));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(fetchIdeasFailed(message));
  }
}

function* idea({ payload }) {
  try {
    const { data } = yield Axios.get(`${url}${payload}/`);
    yield put(fetchIdeasSuccess([data]));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(fetchIdeasFailed(message));
  }
}

function* patchIdea({ payload: { data, id } }) {
  try {
    const { data: responseData } = yield Axios.patch(`${url}${id}/`, data);
    yield put(patchIdeaSuccess(responseData));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(patchIdeaFailed(message));
  }
}

function* createIdea({ payload }) {
  try {
    const { data } = yield Axios.post(url, payload);
    yield put(fetchIdeasSuccess([data]));
    yield put(push({ pathname: `/ideias/${data.id}` }));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(fetchIdeasFailed(message));
  }
}

function* deleteIdea({ payload: id }) {
  try {
    yield Axios.delete(`${url}${id}/`);
    yield put(deleteIdeaSuccess(id));
    yield put(push("/"));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(deleteIdeaFailed(message));
  }
}

export function* watchFetchIdeas() {
  yield takeEvery(FETCH_IDEAS, ideas);
}

export function* watchFetchIdea() {
  yield takeEvery(FETCH_IDEA, idea);
}

export function* watchPatchIdea() {
  yield takeEvery(PATCH_IDEA, patchIdea);
}

export function* watchCreateIdea() {
  yield takeEvery(CREATE_IDEA, createIdea);
}

export function* watchDeleteIdea() {
  yield takeEvery(DELETE_IDEA, deleteIdea);
}

function* ideasSaga() {
  yield all([
    fork(watchFetchIdeas),
    fork(watchFetchIdea),
    fork(watchPatchIdea),
    fork(watchCreateIdea),
    fork(watchDeleteIdea),
  ]);
}

export default ideasSaga;
