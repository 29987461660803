import * as filters from "./filters";

// Componente para compor seletores e filtros genéricos
export const filteredSelector = (selector, filter, filterValue) => (state) =>
  filter(selector(state), filterValue);

// Seletores comuns
export const workflowsSelector = (state) => state.Workflows.entities.workflows;

export const ocurrencesSelector = (state) =>
  state.Workflows.entities.ocurrences;

export const workflowsValueSelector = (state) =>
  Object.values(workflowsSelector(state));

export const ocurrencesValueSelector = (state) =>
  Object.values(ocurrencesSelector(state));

export const workflowItemSelector = (id) => (state) =>
  workflowsSelector(state)[id];

// Seletores filtrados
export const workflowsFilteredSelector = (params) =>
  filteredSelector(workflowsValueSelector, filters.paramsFilter, params);

export const ocurrencesFilteredSelector = (params) =>
  filteredSelector(ocurrencesValueSelector, filters.paramsFilter, params);
