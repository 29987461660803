import {
  FETCH_STEPS,
  FETCH_STEPS_SUCCESS,
  FETCH_STEPS_FAILED,
  PATCH_STEP,
  PATCH_STEP_SUCCESS,
  PATCH_STEP_FAILED,
  CREATE_STEP,
  DELETE_STEP,
  DELETE_STEP_FAILED,
  DELETE_STEP_SUCCESS,
} from "./constants";

export const fetchSteps = (params) => ({
  type: FETCH_STEPS,
  payload: params,
});

export const fetchStepsSuccess = (goals) => ({
  type: FETCH_STEPS_SUCCESS,
  payload: goals,
});

export const fetchStepsFailed = (error) => ({
  type: FETCH_STEPS_FAILED,
  payload: error,
});

export const patchStep = (id, data) => {
  return {
    type: PATCH_STEP,
    payload: { id, data },
  };
};

export const patchStepSuccess = (step) => ({
  type: PATCH_STEP_SUCCESS,
  payload: step,
});

export const patchStepFailed = (error) => ({
  type: PATCH_STEP_FAILED,
  payload: error,
});

export const createStep = (data) => ({
  type: CREATE_STEP,
  payload: data,
});

export const deleteStep = (id) => ({
  type: DELETE_STEP,
  payload: id,
});

export const deleteStepSuccess = (id) => ({
  type: DELETE_STEP_SUCCESS,
  payload: id,
});

export const deleteStepFailed = (error) => ({
  type: DELETE_STEP_FAILED,
  payload: error,
});
