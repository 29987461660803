import React from "react";
import Icon from "../Icon";

const iconStyle = {
  fillOpacity: "0.2",
  size: 15,
  className: "mr-1",
};

export const checkinTypes = {
  6: {
    color: "darkviolet",
    label: "Tarefa",
    icon: <Icon type="checkbox" {...iconStyle} />,
    value: "TASK",
  },
  5: {
    color: "darkviolet",
    label: "Ajuste",
    icon: <Icon type="edit" {...iconStyle} />,
    value: "FIX",
  },
  4: {
    color: "darkviolet",
    label: "Planejamento",
    icon: <Icon type="month" {...iconStyle} />,
    value: "PLANNING",
  },
  3: {
    color: "darkviolet",
    label: "Pesquisa",
    icon: <Icon type="research" {...iconStyle} />,
    value: "RESEARCH",
  },
  2: {
    color: "darkviolet",
    label: "Entrega",
    icon: <Icon type="rocket" {...iconStyle} />,
    value: "CONCLUSION",
  },
  1: {
    color: "darkviolet",
    label: "Reunião",
    icon: <Icon type="meet" {...iconStyle} />,
    value: "MEETING",
  },
};

export default function Level({ type, HtmlElement = "small", ...otherProps }) {
  type = type[Number(type)];
  let { icon, label } = type;
  return (
    <HtmlElement {...otherProps}>
      {icon}
      <span className="text-gray">{label}</span>
    </HtmlElement>
  );
}
