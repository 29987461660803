import React, { useContext } from "react";

export const UserContext = React.createContext({
  user: {
    id: "",
    profile_image: "",
    first_name: "",
    email: "",
    role: "",
    company: {},
  },
});

export function useUser() {
  const context = useContext(UserContext);
  return [context.user, context.updateUser];
}

export function useCompany() {
  const context = useContext(UserContext);
  return [context.user.company, context.updateUser];
}
