import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import classnames from "classnames";

import Icon from "./Icon";
import RemoveAlert from "./RemoveAlert";
import Tooltip from "./Tooltip";

import styled from "styled-components";

const SiderModal = styled(Modal)`
  .tag {
    max-width: 330px;
    overflow: visible;
    .modal-body {
      padding: 1.5rem 1rem;
      overflow-x: visible;
    }
  }
  .custom-dialog {
    max-width: 650px;
  }
  .selectLevel {
    max-width: 300px;
    .modal-body {
      padding: 1.5em 0;
    }
  }
  .btn-secondary {
    border-bottom-left-radius: 9px !important;
  }
  .btn-primary {
    border-bottom-right-radius: 9px !important;
  }
  .ant-modal-content {
    border-radius: 9px;
  }
  .ant-modal-header {
    border-radius: 9px 9px 0 0;
    background: var(--neutralblue);
  }
  .ant-modal-footer {
    padding: 0;
    display: flex;
    & > * {
      width: 100%;
      margin: 0;
      border-radius: 0;
    }
  }
`;

export default function ModalForm({
  contentType,
  newItem,
  children,
  onHide,
  onSubmit,
  onDelete,
  loading,
  removeAlertTitle,
  removeAlertText,
  blocked = false,
  helpText,
  footerText,
  footerCancelText,
  onCancel,
  visible = true,
  className,
  ...props
}) {
  // Logica para criar animacao do modal e corrigir problemas ao entrar diretamente na pagina (sem ser redirecionado)

  const handleRemove = () =>
    RemoveAlert({
      title:
        removeAlertTitle || `Você tem certeza que deseja remover esse item?`,
      text: removeAlertText,
      continueCallback: onDelete,
    });

  return (
    <SiderModal
      visible={visible}
      onCancel={onHide}
      className={classnames("editable-modal", className)}
      title={
        <>
          {contentType}
          {helpText && (
            <Tooltip title={helpText}>
              <i className="mdi mdi-help-circle ml-3 text-gray" />
            </Tooltip>
          )}
        </>
      }
      footer={
        <>
          <button className="btn btn-secondary" onClick={onCancel || onHide}>
            {footerCancelText || "Cancelar"}
          </button>
          <button
            className="btn btn-primary"
            onClick={onSubmit}
            disabled={blocked || loading}
          >
            {loading ? (
              <i className="mdi mdi-loading mdi-spin h6 mb-0" />
            ) : (
              footerText || (newItem ? "Criar" : "Salvar")
            )}
          </button>
        </>
      }
      {...props}
    >
      {children}
      {!newItem && (
        <div className="mt-3">
          <button className="btn btn-danger btn-sm" onClick={handleRemove}>
            <Icon type="delete" className="m-r:.8" />
            Excluir
          </button>
        </div>
      )}
      {/* {!props.border ? (
          <Modal.Header closeButton>
            
          </Modal.Header>
        ) : null}
        
        */}
    </SiderModal>
  );
}
