import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Card } from "antd";
import moment from "moment";

import { MyCheckins } from "../Home/DashboardView";
import CreateNewPriority from "../../components/onboarding/PriorityCard";

export default function Onboarding({ user, step, form }) {
  const { t } = useTranslation();

  const steps = [
    <Form.Item label={t("onboarding.step_1.label_1")}>
      <Card>
        <div className=" d:f a-i:c p-r:3">
          <img
            style={{ width: "68px", height: "60px" }}
            src={user.company?.logo}
          />
          <h4 className="m-l:8">{user.company?.name}</h4>
        </div>
      </Card>
    </Form.Item>,
    <>
      {/* card para a criação do foco da semana*/}
      <Form.Item label={t("onboarding.step_5.label_1")} className="t-a:l">
        <CreateNewPriority user={user} />
      </Form.Item>
    </>,
    <>
      {/* card modificado para a criação de checkins*/}
      <Form.Item label={t("onboarding.step_6.label_1")} className="t-a:l">
        <MyCheckins date={moment()} />
      </Form.Item>
    </>,
  ];

  return (
    <Form layout="vertical" preserve form={form}>
      {steps[step]}
    </Form>
  );
}
