import api from "../../services/api";
import * as constants from "./constants";

export const fetchWorkflows = (params) => async (dispatch) => {
  try {
    const { data: workflows } = await api.get("/workflows/", { params });
    dispatch({
      type: constants.LOAD_ALL_PARAM_SUCCESS,
      payload: workflows,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: constants.LOAD_ALL_PARAM_FAILURE,
      payload: params,
    });
  }
};

export const fetchOcurrences = (params) => async (dispatch) => {
  try {
    const { data: workflows } = await api.get("/workflows/member_ocurrences/", {
      params,
    });
    dispatch({
      type: constants.LOAD_OCURRENCES_PARAM_SUCCESS,
      payload: workflows,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: constants.LOAD_OCURRENCES_PARAM_FAILURE,
      payload: params,
    });
  }
};

export const fetchWorkflowItem = (id) => async (dispatch) => {
  try {
    const { data: workflowItem } = await api.get(`/workflows/${id}/`);
    dispatch({
      type: constants.LOAD_ITEM_SUCCESS,
      payload: workflowItem,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: constants.LOAD_ITEM_FAILURE,
      payload: id,
    });
  }
};

export const createWorkflow = (values) => async (dispatch) => {
  try {
    const { data: postedData } = await api.post("/workflows/", values);
    dispatch({
      type: constants.CREATE_ITEM_SUCCESS,
      payload: postedData,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: constants.CREATE_ITEM_FAILURE,
      payload: values,
    });
  }
};

export const deleteWorkflow = (id) => async (dispatch) => {
  try {
    await api.delete(`/workflows/${id}/`);
    dispatch({
      type: constants.DELETE_ITEM_SUCCESS,
      payload: id,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: constants.DELETE_ITEM_FAILURE,
      payload: id,
    });
  }
};
