export const FETCH_STEPS = "FETCH_STEPS";
export const FETCH_STEPS_SUCCESS = "FETCH_STEPS_SUCCESS";
export const FETCH_STEPS_FAILED = "FETCH_STEPS_FAILED";

export const PATCH_STEP = "PATCH_STEP";
export const PATCH_STEP_SUCCESS = "PATCH_STEP_SUCCESS";
export const PATCH_STEP_FAILED = "PATCH_STEP_FAILED";

export const CREATE_STEP = "CREATE_STEP";

export const DELETE_STEP = "DELETE_STEP";
export const DELETE_STEP_SUCCESS = "DELETE_STEP_SUCCESS";
export const DELETE_STEP_FAILED = "DELETE_STEP_FAILED";
