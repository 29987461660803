import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Checkbox, Form } from "antd";
import useSWR from "swr";

import { get } from "../../services/api";
import ManageTags from "./ManageTags";

const ColorSquare = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;

export default function TagPopover({ value, onChange }) {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const { data: tags, mutate } = useSWR("/tags/", get);

  useEffect(
    () => () => {
      onChange(form.getFieldsValue(true));
    },
    [form, onChange]
  );

  return (
    <>
      <Form form={form} initialValues={{ tags: value }}>
        <Form.Item noStyle name="tags">
          <Checkbox.Group className="w:full">
            {tags?.map((tag) => (
              <label className="d:f j-c:s-b m-b:.7">
                <span className="d:f a-i:c m-r:1">
                  <ColorSquare
                    style={{ backgroundColor: `${tag.color}` }}
                    className="m-l:0 m-r:.5"
                  />
                  {tag.name}
                </span>
                <Checkbox className="m-b:0 m-l:a" value={tag.id} />
              </label>
            ))}
          </Checkbox.Group>
        </Form.Item>
        <hr className="m-y:.6" />
        <div className="t-a:c">
          <a
            href="#void"
            // className="w:full d:b footer"
            onClick={() => setModalVisible(true)}
          >
            Gerenciar tags
          </a>
        </div>
      </Form>
      {modalVisible && (
        <ManageTags
          visible
          hideModal={() => setModalVisible(false)}
          // onUpdate={() => mutatePriorityItems()}
          tags={tags}
          mutate={mutate}
        />
      )}
    </>
  );
}
