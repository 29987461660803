import React from "react";
import styled from "styled-components";
import Icon from "../Icon";

export default function StepButtons({
  handleBack,
  handleContinue,
  disableBack = false,
  disableContinue = false,
}) {
  return (
    <div className="j-c:e d:f pos:a bottom:3 flx-d:c right:2">
      <Button
        type="primary"
        className="m-b:1"
        onClick={() => (!disableBack ? handleBack() : undefined)}
        style={{ color: disableBack ? "#AAAAAA" : "#4b61e9" }}
      >
        <Icon type="chevronUp" />
      </Button>
      <Button
        type="primary"
        onClick={() => (!disableContinue ? handleContinue() : undefined)}
        style={{ color: disableContinue ? "#AAAAAA" : "#4b61e9" }}
      >
        <Icon type="chevronDown" />
      </Button>
    </div>
  );
}

const Button = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #cccccc;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
