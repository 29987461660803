import React from "react";
import { Checkbox } from "antd";
import classnames from "classnames";

import PriorityBody from "../../weekly-priorities/PriorityBody";
import Tag from "../../tags/Tag";
import ActionsMenu from "../../common/ActionsMenu";
import TrailingIcons from "../../common/TrailingIcons";

export default function Checkin({
  data,
  setEditing,
  handlePatch,
  handleDelete,
  handleChangeStatus,
  dataType = "CHECKIN",
}) {
  return (
    <PriorityBody className="d:f flx-d:r pos:r a-i:b ">
      <div className="m-r:.8">
        <Checkbox checked={data?.finished} onChange={handleChangeStatus} />
      </div>
      <div className="d:i-b a-i:c m-r:.2">
        {data?.tags?.map((tag) => (
          <Tag
            color={tag.color}
            text={tag.name}
            key={`ed-checkin-tag${tag.name}`}
          />
        ))}
      </div>
      <div className="d:f flx-d:c a-i:f-s w:full">
        <h6
          className={classnames(
            "d:f f-w:400 f:1 cur:p m-b:0 w:full",
            data?.finished ? "t-d:l-t text-gray" : undefined
          )}
          onClick={() => setEditing(true)}
          style={{ lineHeight: 2 }}
        >
          {data?.title}
        </h6>
        <TrailingIcons shortcuts={data?.shortcuts} />
      </div>

      <div className="m-l:a">
        <ActionsMenu
          onDelete={handleDelete}
          onUpdate={handlePatch}
          data={data}
          dataType={dataType}
        />
      </div>
    </PriorityBody>
  );
}
