import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { UserContext } from "../context/UserContext";
import PoliciesModal from "../components/policies/PoliciesModal";
import Onboarding from "../pages/onboarding";
import CreateCompany from "../pages/onboarding/CreateCompany";

export default withRouter(function UserProvider({ history, children }) {
  const [user, setUser] = useState();
  const [modalOpened, setModalOpened] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState();
  const [termsOfUse, setTermsOfUse] = useState();

  const openPoliciesModal = () => {
    setModalOpened(true);
    axios.get("/policies/").then(({ data }) => {
      setPrivacyPolicy(data.privacy_policy);
      setTermsOfUse(data.terms_of_use);
      if (!(data.privacy_policy || data.terms_of_use)) setModalOpened(false);
    });
  };

  const fetchUser = () =>
    axios
      .get(`/users/get_user_info/`)
      .then(({ data }) => {
        // if (!data.member_id) history.replace("/criar-empresa/");
        setUser(data);
      })
      .catch(({ response: { status, data } }) => {
        if (status === 402) window.location.href = "/plano-expirado";
        else if (status === 401) {
          setUser(data);
          openPoliciesModal();
        } else if (status !== 404)
          window.location.href = process.env.REACT_APP_AUTH_URL + "/login/";
        else history.push("/onboarding/");
      });

  useEffect(() => {
    if (user === undefined) {
      setUser(false);
      fetchUser();
    }
  }, [user]);
  let componentRender;
  if (user)
    if (!user.member_id) componentRender = <CreateCompany />;
    else if (!user.onboarding_finished) componentRender = <Onboarding />;
    else componentRender = children;
  return (
    <UserContext.Provider value={{ user, updateUser: fetchUser }}>
      {componentRender}
      {modalOpened && (
        <PoliciesModal
          show={modalOpened}
          onClose={() => setModalOpened(false)}
          termsOfUse={termsOfUse}
          privacyPolicy={privacyPolicy}
        />
      )}
    </UserContext.Provider>
  );
});
