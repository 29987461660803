import React, { useState } from "react";
import { message } from "antd";
import { useDispatch } from "react-redux";
import moment from "moment";

import api from "../../../services/api";
import { displayGenericError } from "../../../utils/messages";
import RemoveAlert from "../../common/RemoveAlert";
import InputField from "../../common/forms/InputField";
import Checkin from "../EditableCheckin/Checkin";
import { createCheckin } from "../../../redux/checkins/actions";
import {
  editActivity,
  deleteActivity,
} from "../../../redux/activities/actions";

export default function EditableActivity({ data }) {
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();
  const today = moment().format("YYYY-MM-DD");

  const handlePatch = (values) => {
    if (values.date !== undefined) {
      createNewCheckin({ checkinDate: values.date, finished: false });
    } else {
      dispatch(editActivity({ ...data, ...values }));
    }
  };

  const handleDelete = () => dispatch(deleteActivity(data.id));

  const showDeleteConfirmation = () => {
    RemoveAlert({
      customTitle: "Você tem certeza que deseja remover este checkin?",
      continueCallback: handleDelete,
    });
  };

  const createNewCheckin = ({ checkinDate, finished = false }) => {
    dispatch(
      createCheckin({
        title: data.title,
        tags:
          data.tags !== undefined ? data.tags.map((tag) => tag.id) : undefined,
        date: checkinDate,
        finished: finished,
      })
    );
    handleDelete();
  };

  const handleBlur = (e) => {
    if (e.target.value.length > 0 && e.target.value !== data.title)
      handlePatch({ title: e.target.value });
    setEditing(false);
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13) e.target.blur();
  };

  return (
    <div className="d:f a-i:c">
      {editing ? (
        <InputField
          defaultValue={data.title}
          onBlur={handleBlur}
          autoFocus
          onKeyUp={handleEnter}
          maxLength={150}
        />
      ) : (
        <Checkin
          data={data}
          setEditing={setEditing}
          handlePatch={handlePatch}
          handleDelete={showDeleteConfirmation}
          handleChangeStatus={() =>
            createNewCheckin({
              checkinDate: today,
              finished: true,
            })
          }
          dataType="ACTIVITY"
        />
      )}
    </div>
  );
}
