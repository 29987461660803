import produce from "immer";
// import { displayGenericError } from "../../../utils/messages";
// displayGenericError();

// import { message } from "react";
// message.error(t("requestError.label"));

// message.success("Check-in criado!");
// message.error("Parece que houve um erro, tente novamente mais tarde");

import * as constants from "./constants";

const initialState = {
  entities: {},
};

const Checkins = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case constants.LOAD_ALL_PARAM_SUCCESS:
        draft.entities = {
          ...draft.entities,
          ...action.payload.reduce((nextState, checkin) => {
            nextState[checkin.id] = { ...checkin };
            return nextState;
          }, {}),
        };
        break;

      case constants.CREATE_ITEM_SUCCESS:
        draft.entities[action.payload.id] = action.payload;
        break;

      case constants.EDIT_ITEM_SUCCESS:
        draft.entities[action.payload.id] = action.payload;
        break;

      case constants.DELETE_ITEM_SUCCESS:
        delete draft.entities[action.payload];
        break;

      default:
        return state;
    }
  });

export default Checkins;
