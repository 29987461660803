import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Icon from "../Icon";

const StyledButton = styled.button`
  padding: ${(props) => props.style?.padding};
  .btn-transparent {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }
`;

export default function Button({
  shape,
  size,
  type,
  icon,
  className = "",
  disabled,
  htmlType,
  ...props
}) {
  switch (shape) {
    case "circle":
      className = className.concat(" rounded-circle");
      break;
    default:
      break;
  }

  switch (type) {
    case "white":
      className = className.concat(" white-btn");
      break;
    case "primary":
      className = className.concat(" btn btn-primary bg-primary");
      break;
    case "secondary":
      className = className.concat(" btn btn-secondary bg-secondary");
      break;
    case "transparent":
      className = className.concat(" btn btn-transparent");
      break;
    case "text":
      className = className.concat(" btn btn-no-style");
      break;
    case "danger":
      className = className.concat(" bg-danger text-white");
      break;
    case "ghost":
      className = className.concat(" bg-gray text-white");
      break;
    default:
      break;
  }
  switch (size) {
    case "small":
      className = className.concat(" btn-sm");
      break;
    default:
      break;
  }
  if (icon && !props.children)
    className = className.concat(" icon-btn icon-btn--35");

  if (!props.children && icon)
    props.children = typeof icon === "string" ? <Icon type={icon} /> : icon;

  return (
    <StyledButton
      className={className}
      disabled={disabled}
      type={htmlType}
      {...props}
    />
  );
}

Button.propTypes = {
  shape: PropTypes.oneOf([undefined, "circle"]),
  size: PropTypes.oneOf([undefined, "small"]),
  type: PropTypes.oneOf([
    "primary",
    "secondary",
    "white",
    "transparent",
    "text",
    "ghost",
  ]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  htmlType: PropTypes.string,
};
