export const LOAD_ALL_PARAM_SUCCESS = "CHECKINS:LOAD_ALL_PARAM_SUCCESS";
export const LOAD_ALL_PARAM_FAILURE = "CHECKINS:LOAD_ALL_PARAM_FAILURE";

export const CREATE_ITEM_SUCCESS = "CHECKINS:CREATE_ITEM_SUCCESS";
export const CREATE_ITEM_FAILURE = "CHECKINS:CREATE_ITEM_FAILURE";

export const EDIT_ITEM_SUCCESS = "CHECKINS:EDIT_ITEM_SUCCESS";
export const EDIT_ITEM_FAILURE = "CHECKINS:EDIT_ITEM_FAILURE";

export const DELETE_ITEM_SUCCESS = "CHECKINS:DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAILURE = "CHECKINS:DELETE_ITEM_FAILURE";
