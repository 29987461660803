// @flow
import {
  FETCH_STEPS,
  FETCH_STEPS_SUCCESS,
  FETCH_STEPS_FAILED,
  PATCH_STEP,
  PATCH_STEP_SUCCESS,
  PATCH_STEP_FAILED,
  CREATE_STEP,
  DELETE_STEP,
  DELETE_STEP_FAILED,
  DELETE_STEP_SUCCESS,
} from "./constants";
import { mergeDistinct } from "../../utils";

const INIT_STATE = {
  steps: [],
  loaded: false,
  loading: false,
};

const Steps = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_STEPS:
      return { ...state, loading: true, loaded: false };
    case FETCH_STEPS_SUCCESS:
      return {
        ...state,
        steps: mergeDistinct(state.steps, action.payload, "id"),
        loading: false,
        error: null,
        loaded: true,
      };
    case FETCH_STEPS_FAILED:
      return { ...state, error: action.payload, loading: false, loaded: true };

    case PATCH_STEP:
      return { ...state, loading: true };
    case PATCH_STEP_SUCCESS:
      const steps = [...state.steps];
      const index = steps.findIndex(({ id }) => id == action.payload.id);
      steps[index] = action.payload;
      return { ...state, steps };
    case PATCH_STEP_FAILED:
      return { ...state };

    case DELETE_STEP:
      return { ...state, loading: true };
    case DELETE_STEP_SUCCESS:
      return {
        ...state,
        steps: state.steps.filter(({ id }) => id != action.payload),
      };
    case DELETE_STEP_FAILED:
      return { ...state, error: action.payload, loading: false, loaded: true };

    case CREATE_STEP:
      return { ...state, loading: true };

    default:
      return { ...state };
  }
};

export default Steps;
