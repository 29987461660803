import React from "react";
import { Checkbox, Tooltip } from "antd";
import classnames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";

import PriorityBody from "../../weekly-priorities/PriorityBody";
import Tag from "../../tags/Tag";
import ActionsMenu from "../../common/ActionsMenu";
import TrailingIcons from "../../common/TrailingIcons";

export default function MinifiedCheckin({
  data,
  date,
  setEditing,
  handlePatch,
  handleDelete,
  handleChangeStatus,
}) {
  const today = moment();
  const { t } = useTranslation();

  return (
    <PriorityBody className="d:f flx-d:c pos:r a-i:b">
      <div className="d:f flx-d:r a-i:c w:full">
        <div className="m-r:.8">
          <Checkbox checked={data?.finished} onChange={handleChangeStatus} />
        </div>
        <span
          className="f:.9 m-r:.6 t-t:u"
          style={{
            color: data?.finished
              ? "#bdbdbd"
              : date.isBefore(today, "day")
              ? "red"
              : "#828282",
          }}
        >
          {data?.finished
            ? `${t("done.label")} >`
            : date.isBefore(today, "day")
            ? `${t("overdue.label")} >`
            : `To do >`}
        </span>
        {data?.tags?.length > 0 && (
          <Tooltip
            title={data?.tags?.map((tag) => (
              <Tag
                color={tag.color}
                text={tag.name}
                key={`min-checkin-tag-${tag.name}`}
              />
            ))}
          >
            <div className="d:i-b m-y:.8">
              <Tag color={data?.tags[0]?.color} text={`${data?.tags.length}`} />
            </div>
          </Tooltip>
        )}
        <div className="m-l:a">
          <ActionsMenu
            onDelete={handleDelete}
            onUpdate={handlePatch}
            data={data}
            dataType="CHECKIN"
          />
        </div>
      </div>
      <div className="d:f flx-d:c a-i:f-s w:full">
        <h6
          className={classnames(
            "f-w:400 f:1 cur:p m-b:0",
            data?.finished && "t-d:l-t"
          )}
          onClick={() => setEditing(true)}
          style={{
            lineHeight: 2,
            whiteSpace: "break-spaces",
            color: data?.finished && "#bdbdbd",
          }}
        >
          {data?.title}
        </h6>
        <TrailingIcons shortcuts={data?.shortcuts} />
      </div>
    </PriorityBody>
  );
}
