// @flow
import { all, fork, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import { push } from "connected-react-router";

import { FETCH_STEPS, PATCH_STEP, CREATE_STEP, DELETE_STEP } from "./constants";
import { FETCH_IDEAS_SUCCESS } from "../ideas/constants";

import {
  fetchStepsFailed,
  fetchStepsSuccess,
  patchStepFailed,
  patchStepSuccess,
  deleteStepSuccess,
  deleteStepFailed,
} from "./actions";

const url = "/api/steps/";

function* steps({ payload }) {
  try {
    const { data } = yield Axios.get(url, {
      params: payload,
    });
    yield put(fetchStepsSuccess(data));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(fetchStepsFailed(message));
  }
}

function* patchStep({ payload: { data, id } }) {
  try {
    const { data: responseData } = yield Axios.patch(`${url}${id}/`, data);
    yield put(patchStepSuccess(responseData));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(patchStepFailed(message));
  }
}

function* createStep({ payload }) {
  try {
    const { data } = yield Axios.post(url, payload);
    yield put(fetchStepsSuccess([data]));
    yield put(push({ pathname: `/etapas/${data.id}` }));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(patchStepFailed(message));
  }
}

function* deleteStep({ payload: id }) {
  try {
    yield Axios.delete(`${url}${id}/`);
    yield put(push("/"));
    yield put(deleteStepSuccess(id));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(deleteStepFailed(message));
  }
}

function* interceptIdea({ payload, type }) {
  yield put(
    fetchStepsSuccess(
      payload.reduce((acc, { steps }) => [...acc, ...steps], [])
    )
  );
}

export function* watchFetchSteps() {
  yield takeEvery(FETCH_STEPS, steps);
}

export function* watchPatchStep() {
  yield takeEvery(PATCH_STEP, patchStep);
}

export function* watchCreateStep() {
  yield takeEvery(CREATE_STEP, createStep);
}

export function* watchFetchIdea() {
  yield takeEvery(FETCH_IDEAS_SUCCESS, interceptIdea);
}

export function* watchDeleteStep() {
  yield takeEvery(DELETE_STEP, deleteStep);
}

function* stepsSaga() {
  yield all([
    fork(watchFetchSteps),
    fork(watchPatchStep),
    fork(watchCreateStep),
    fork(watchFetchIdea),
    fork(watchDeleteStep),
  ]);
}

export default stepsSaga;
