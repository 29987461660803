// @flow
import { all, fork, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import { push } from "connected-react-router";

import {
  FETCH_KEYRESULTS,
  FETCH_KEYRESULT,
  PATCH_KEYRESULT,
  CREATE_KEYRESULT,
  DELETE_KEYRESULT,
} from "./constants";
import {
  fetchKeyResultssFailed,
  fetchKeyResultsSuccess,
  patchKeyResultsFailed,
  patchKeyResultsSuccess,
  deleteKeyResultsSuccess,
  deleteKeyResultsFailed,
} from "./actions";

const url = "goals/key_results/";

function* KeyResults({ payload }) {
  try {
    const { data } = yield Axios.get(url, {
      params: payload,
    });
    yield put(fetchKeyResultsSuccess(data));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(fetchKeyResultssFailed(message));
  }
}

function* KeyResult({ payload }) {
  try {
    const { data } = yield Axios.get(`${url}${payload}/`);
    yield put(fetchKeyResultsSuccess([data]));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(fetchKeyResultssFailed(message));
  }
}

function* patchKeyResults({ payload: { data, id } }) {
  try {
    const { data: responseData } = yield Axios.patch(`${url}${id}/`, data);
    yield put(patchKeyResultsSuccess(responseData));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }

    yield put(patchKeyResultsFailed(message));
  }
}

function* createKeyResults({ payload }) {
  try {
    const { data } = yield Axios.post("goals/key_results/", payload);

    yield put(fetchKeyResultsSuccess([data]));
    yield put(push({ pathname: `/objetivos/resultado-chave/${data.id}` }));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }

    yield put(fetchKeyResultssFailed(message));
  }
}

function* deleteKeyResults({ payload: id }) {
  try {
    yield Axios.delete(`${url}${id}/`);
    yield put(deleteKeyResultsSuccess(id));
    yield put(push({ pathname: `/objetivos/` }));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(deleteKeyResultsFailed(message));
  }
}

export function* watchFetchKeyResultss() {
  yield takeEvery(FETCH_KEYRESULTS, KeyResults);
}

export function* watchFetchKeyResults() {
  yield takeEvery(FETCH_KEYRESULT, KeyResult);
}

export function* watchPatchKeyResults() {
  yield takeEvery(PATCH_KEYRESULT, patchKeyResults);
}

export function* watchCreateKeyResults() {
  yield takeEvery(CREATE_KEYRESULT, createKeyResults);
}

export function* watchDeleteKeyResults() {
  yield takeEvery(DELETE_KEYRESULT, deleteKeyResults);
}

function* KeyResultsSaga() {
  yield all([
    fork(watchFetchKeyResultss),
    fork(watchFetchKeyResults),
    fork(watchPatchKeyResults),
    fork(watchCreateKeyResults),
    fork(watchDeleteKeyResults),
  ]);
}

export default KeyResultsSaga;
