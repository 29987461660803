import Swal from "sweetalert2/dist/sweetalert2.js";

const RemoveAlert = ({
  customTitle = "Você tem certeza?",
  confirmText = "Remover",
  continueCallback,
  text = "Esta ação não poderá ser desfeita!",
}) => {
  Swal.fire({
    title: customTitle,
    text,
    type: "warning",
    showCancelButton: true,
    confirmButtonText: confirmText,
    confirmButtonColor: "var(--danger)",
    cancelButtonText: "Cancelar",
    customClass: {
      confirmButton: "btn btn-lg btn-danger",
      cancelButton: "btn btn-lg btn-no-bg",
    },
  }).then((result) => result.value && continueCallback());
};

export default RemoveAlert;
