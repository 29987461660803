import React, { useState } from "react";
import { Modal } from "antd";
import axios from "axios";

import PageLoader from "../common/PageLoader";

const classnames = require("classnames");

export default function PoliciesModal({
  show,
  onClose,
  privacyPolicy,
  termsOfUse,
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [];

  const onChangeStep = () => {
    const url =
      steps[currentStep].type === 0
        ? "accept_privacy_policy"
        : "accept_terms_of_use";
    axios.post("/policies/" + url).then(() => {
      if (currentStep + 1 < steps.length) setCurrentStep(currentStep + 1);
      else onClose();
    });
  };

  if (privacyPolicy)
    steps.push({ ...privacyPolicy, title: "Política de Privacidade", type: 0 });
  if (termsOfUse)
    steps.push({ ...termsOfUse, title: "Termos de Uso", type: 1 });

  return privacyPolicy || termsOfUse ? (
    <Modal
      visible={show}
      width={800}
      title={`Aceitar ${steps[currentStep].title}`}
      okText={"Eu aceito"}
      onOk={onChangeStep}
      closable={false}
      cancelText={false}
    >
      <p>{steps[currentStep].body}</p>
    </Modal>
  ) : (
    <PageLoader />
  );
}
