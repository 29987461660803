export const FETCH_KEYRESULTS = "FETCH_KeyResultsS";
export const FETCH_KEYRESULTS_SUCCESS = "FETCH_KeyResultsS_SUCCESS";
export const FETCH_KEYRESULTS_FAILED = "FETCH_KeyResultsS_FAILED";

export const FETCH_KEYRESULT = "FETCH_KeyResults";

export const PATCH_KEYRESULT = "PATCH_KeyResults";
export const PATCH_KEYRESULT_SUCCESS = "PATCH_KeyResults_SUCCESS";
export const PATCH_KEYRESULT_FAILED = "PATCH_KeyResults_FAILED";

export const CREATE_KEYRESULT = "CREATE_KeyResults";

export const DELETE_KEYRESULT = "DELETE_KeyResults";
export const DELETE_KEYRESULT_SUCCESS = "DELETE_KeyResults_SUCCESS";
export const DELETE_KEYRESULT_FAILED = "DELETE_KeyResults_FAILED";




