import React from "react";
import { createPortal } from "react-dom";
import { Spin } from "antd";
import "styled-components/macro";

export function Loader() {
  return <Spin />;
}

export default function PageLoader() {
  return createPortal(
    <div
      css={`
        position: absolute;
        height: 100vh;
        width: 100vw;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1999;
      `}
    >
      <Loader />
    </div>,
    document.body
  );
}
