import { useTranslation } from "react-i18next";

export default function DefaultTeams() {
  const { t } = useTranslation();
  const options = [
    {
      label: t("onboarding.create_teams.label_1"),
      value: t("onboarding.create_teams.label_1"),
    },
    {
      label: t("onboarding.create_teams.label_2"),
      value: t("onboarding.create_teams.label_2"),
    },
    {
      label: t("onboarding.create_teams.label_3"),
      value: t("onboarding.create_teams.label_3"),
    },
    { label: "Customer Success", value: "Customer Success" },
    { label: "Design", value: "Design" },
    {
      label: t("onboarding.create_teams.label_4"),
      value: t("onboarding.create_teams.label_4"),
    },
    {
      label: t("onboarding.create_teams.label_5"),
      value: t("onboarding.create_teams.label_5"),
    },
    { label: "Growth", value: "Growth" },
    { label: "Marketing", value: "Marketing" },
    { label: "Tech", value: "Tech" },
    {
      label: t("onboarding.create_teams.label_6"),
      value: t("onboarding.create_teams.label_6"),
    },
    {
      label: t("onboarding.create_teams.label_7"),
      value: t("onboarding.create_teams.label_7"),
    },
  ];
  return options;
}
