import React from "react";
import styled from "styled-components";
import { Select } from "antd";

import Invalid from "./Error";

const StyledSelect = styled(Select)`
  .el-select__tags > span {
    display: inline-block !important;
  }

  &:not(.el-input-outlined) {
    i:not(.is-reverse) ~ .el-input__inner:hover {
      background: var(--primary-t);
      padding-left: 8px;
      padding-right: 8px;
    }

    i.is-reverse ~ .el-input__inner {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .el-input .el-input__inner {
    font-weight: 400;
    font-size: 16px;
    transition: 0.25s ease-in-out;
  }
  i.is-reverse ~ .el-input__inner {
    border: 1px solid var(--primary-t);
    box-shadow: 0 0 0 3px rgba(59, 91, 253, 0.1);
  }
`;

const SelectField = React.forwardRef(
  (
    {
      value,
      onChange,
      options = [],
      multiple = false,
      valueLabel = "Selecione",
      error,
      Type = "span",
      editable = true,
      outlined = false,
      optionFilterProp = "label",
      ...props
    },
    ref
  ) => {
    let activeItem;

    if (!multiple) {
      if (value) activeItem = options.filter((item) => item.value === value)[0];
      if (!activeItem && !valueLabel) activeItem = options[0];
    }
    return editable ? (
      [
        <StyledSelect
          placeholder={valueLabel}
          onChange={onChange}
          value={value}
          multiple={multiple}
          ref={ref}
          className={outlined ? "el-input-outlined" : undefined}
          optionFilterProp={optionFilterProp}
          {...props}
        >
          {options?.map((item) => (
            <Select.Option
              key={item.value}
              value={item.value}
              label={item.label}
            >
              {item.icon && <span className="mr-1">{item.icon}</span>}
              <span>{item.label}</span>
            </Select.Option>
          ))}
        </StyledSelect>,
        <Invalid value={error} />,
      ]
    ) : (
      <span>
        {activeItem.icon}
        {activeItem.label}
      </span>
    );
  }
);

export default SelectField;
