// @flow

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import Ideas from "./ideas/reducers";
import Goals from "./goals/reducers";
import Steps from "./steps/reducers";
import Tasks from "./tasks/reducers";
import Location from "./location/reducers";
import KeyResults from "./key_results/reducers";

import Checkins from "./checkins/reducer";
import Workflows from "./workflows/reducer";
import Activities from "./activities/reducer";

const createRootReducer = (history) =>
  combineReducers({
    Location,
    Ideas,
    Goals,
    Steps,
    Tasks,
    KeyResults,
    Checkins,
    Workflows,
    Activities,
    router: connectRouter(history),
  });

export default createRootReducer;
