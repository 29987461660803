import {
  FETCH_GOALS,
  FETCH_GOALS_SUCCESS,
  FETCH_GOALS_FAILED,
  FETCH_GOAL,
  FETCH_GOAL_SUCCESS,
  FETCH_GOAL_FAILED,
  SET_COMPANY_GOALS,
  PATCH_GOAL,
  PATCH_GOAL_SUCCESS,
  PATCH_GOAL_FAILED,
  CREATE_GOAL,
  DELETE_GOAL,
  DELETE_GOAL_FAILED,
  DELETE_GOAL_SUCCESS,
} from "./constants";

// Listagem de canais
export const fetchGoals = (params) => ({
  type: FETCH_GOALS,
  payload: params,
});

export const fetchGoalsSuccess = (goals) => ({
  type: FETCH_GOALS_SUCCESS,
  payload: goals,
});

export const fetchGoalsFailed = (error) => ({
  type: FETCH_GOALS_FAILED,
  payload: error,
});

export const fetchGoal = (params) => (
  {
  type: FETCH_GOAL,
  payload: params,
});

export const fetchGoalSuccess = (data) => ({
  type: FETCH_GOAL_SUCCESS,
  payload: data,
});

export const fetchGoalFailed = (error) => ({
  type: FETCH_GOAL_FAILED,
  payload: error,
});

export const setCompanyGoalsLoaded = () => ({
  type: SET_COMPANY_GOALS,
});

export const patchGoal = (id, data) => {
  return {
    type: PATCH_GOAL,
    payload: { id, data },
  };
};

export const patchGoalSuccess = (goal) => ({
  type: PATCH_GOAL_SUCCESS,
  payload: goal,
});

export const patchGoalFailed = (error) => ({
  type: PATCH_GOAL_FAILED,
  payload: error,
});

export const createGoal = (data) => ({
  type: CREATE_GOAL,
  payload: data,
});

export const deleteGoal = (id) => ({
  type: DELETE_GOAL,
  payload: id,
});

export const deleteGoalSuccess = (data) => ({
  type: DELETE_GOAL_SUCCESS,
  payload: data,
});

export const deleteGoalFailed = (error) => ({
  type: DELETE_GOAL_FAILED,
  payload: error,
});
