import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import Card from "../../common/Card";
import Icon from "../../common/Icon";
import EditableActivity from "./EditableActivity";
import { useUser } from "../../../context/UserContext";
import light from "../../../svgs/light.svg";
import { activitiesValueSelector } from "../../../redux/activities/selectors";
import {
  fetchActivities,
  createActivity,
} from "../../../redux/activities/actions";
import InputField from "../../common/forms/InputField";

export const StyledDiv = styled.div`
  .text {
    text-align: center;
    color: #1d2644;
  }
`;

function CreateNewActivity() {
  const dispatch = useDispatch();

  const handleEnter = (e) => {
    if (e.keyCode !== 13 || e.target.value.length === 0) return;
    dispatch(createActivity({ title: e.target.value }));
    e.target.value = "";
    e.target.blur();
  };

  return (
    <div className="d:f a-i:c">
      <InputField
        placeholder={"Crie um novo item e aperte enter ou ↩"}
        onKeyUp={handleEnter}
        maxLength={120}
      />
    </div>
  );
}

function PersonalActivities({ data }) {
  return (
    <div>
      <ul>
        {data.map((item) => (
          <li className="m-b:.6" key={`activity-li-item-${item.id}`}>
            <EditableActivity data={item} />
          </li>
        ))}
      </ul>
      <CreateNewActivity />
    </div>
  );
}

export default function Activities() {
  const { t } = useTranslation();
  const [user] = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchActivities({
        author: user.member_id,
      })
    );
  }, [dispatch, user.member_id]);

  const activities = useSelector(activitiesValueSelector);

  return (
    <Card>
      <h5 className="text-darkviolet-sec m-b:1">
        <Icon type="progress" className="m-r:.8" />
        Backlog <span className="m-l:.3 f:.9">({activities.length})</span>
      </h5>
      {activities.length === 0 && (
        <StyledDiv className="d:f flx-d:c m-b:2 a-i:c">
          <span className="f:10">
            <img src={light} />
          </span>
          <span className="text">{t("backlogs-empty.label")}</span>
        </StyledDiv>
      )}
      <PersonalActivities data={activities} />
    </Card>
  );
}
