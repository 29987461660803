// @flow
import { all, fork, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import { push } from "connected-react-router";

import {
  FETCH_GOALS,
  FETCH_GOAL,
  PATCH_GOAL,
  CREATE_GOAL,
  DELETE_GOAL,
} from "./constants";
import {
  fetchGoalsFailed,
  fetchGoalsSuccess,
  patchGoalSuccess,
  patchGoalFailed,
  deleteGoalSuccess,
} from "./actions";

const url = "goals/";

function* goals({ payload }) {
  try {
    const { data } = yield Axios.get(url, {
      params: payload,
    });
    yield put(fetchGoalsSuccess(data));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(fetchGoalsFailed(message));
  }
}

function* goal({ payload }) {
  try {
    const { data } = yield Axios.get(`${url}${payload}/`);
    yield put(fetchGoalsSuccess([data]));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(fetchGoalsFailed(message));
  }
}

function* patchGoal({ payload: { data, id } }) {
  try {
    const { data: responseData } = yield Axios.patch(`${url}${id}/`, data);
    yield put(patchGoalSuccess(responseData));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(patchGoalFailed(message));
  }
}

function* createGoal({ payload }) {
  try {
    const { data } = yield Axios.post(`${url}`, payload);
    yield put(fetchGoalsSuccess([data]));

    // yield put(push({ pathname: `${data.id}` }));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(fetchGoalsFailed(message));
  }
}

function* deleteGoal({ payload: id }) {
  try {
    yield Axios.delete(`${url}${id}/`);
    yield put(deleteGoalSuccess(id));
    yield put(push({ pathname: `/objetivos/` }));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(patchGoalFailed(message));
  }
}

export function* watchFetchGoals() {
  yield takeEvery(FETCH_GOALS, goals);
}

export function* watchFetchGoal() {
  yield takeEvery(FETCH_GOAL, goal);
}

export function* watchPatchGoal() {
  yield takeEvery(PATCH_GOAL, patchGoal);
}

export function* watchCreateGoal() {
  yield takeEvery(CREATE_GOAL, createGoal);
}

export function* watchDeleteGoal() {
  yield takeEvery(DELETE_GOAL, deleteGoal);
}

function* goalsSaga() {
  yield all([
    fork(watchFetchGoals),
    fork(watchFetchGoal),
    fork(watchPatchGoal),
    fork(watchCreateGoal),
    fork(watchDeleteGoal),
  ]);
}

export default goalsSaga;
