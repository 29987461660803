import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Space } from "antd";

import Icon from "../Icon";

const TrailingIconDiv = styled.div`
  padding: 0px 8px;
  background: #f8f8f9;
  border: 0.5px solid #a6b3f7;
  box-sizing: border-box;
  border-radius: 4px;
`;

const StyledImg = styled.img`
  width: 1em;
  height: 1em;
  object-fit: fill;
  object-position: 50% 50%;
`;

export function TrailingIcon({ shortcut }) {
  return shortcut ? (
    <a href={shortcut.link} target="_blank" rel="noopener noreferrer">
      <TrailingIconDiv>
        {shortcut.logo ? (
          <StyledImg src={shortcut.logo} />
        ) : (
          <Icon type="integrations" />
        )}
      </TrailingIconDiv>
    </a>
  ) : (
    false
  );
}
TrailingIcon.propTypes = {
  shortcut: PropTypes.object,
};

function TrailingIcons({ className, shortcuts }) {
  return shortcuts ? (
    <div className={className || "d:f flx-d:r m-t:.3"}>
      <Space>
        {shortcuts.map((shortcut) => (
          <TrailingIcon shortcut={shortcut} key={`shortcuts-${shortcut.id}`} />
        ))}
      </Space>
    </div>
  ) : (
    false
  );
}
TrailingIcons.propTypes = {
  className: PropTypes.string,
  shortcuts: PropTypes.array,
};

export default TrailingIcons;
