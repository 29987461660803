export const FETCH_IDEAS = "FETCH_IDEAS";
export const FETCH_IDEAS_SUCCESS = "FETCH_IDEAS_SUCCESS";
export const FETCH_IDEAS_FAILED = "FETCH_IDEAS_FAILED";

export const FETCH_IDEA = "FETCH_IDEA";

export const PATCH_IDEA = "PATCH_IDEA";
export const PATCH_IDEA_SUCCESS = "PATCH_IDEA_SUCCESS";
export const PATCH_IDEA_FAILED = "PATCH_IDEA_FAILED";

export const CREATE_IDEA = "CREATE_IDEA";

export const DELETE_IDEA = "DELETE_IDEA";
export const DELETE_IDEA_SUCCESS = "DELETE_IDEA_SUCCESS";
export const DELETE_IDEA_FAILED = "DELETE_IDEA_FAILED";
