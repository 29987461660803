import {
  FETCH_TASKS,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILED,
  FETCH_TASK,
  PATCH_TASK,
  PATCH_TASK_SUCCESS,
  PATCH_TASK_FAILED,
  CREATE_TASK,
  DELETE_TASK,
  DELETE_TASK_FAILED,
  DELETE_TASK_SUCCESS,
} from "./constants";

export const fetchTasks = (params) => ({
  type: FETCH_TASKS,
  payload: params,
});

export const fetchTasksSuccess = (goals) => ({
  type: FETCH_TASKS_SUCCESS,
  payload: goals,
});

export const fetchTasksFailed = (error) => ({
  type: FETCH_TASKS_FAILED,
  payload: error,
});

export const fetchTask = (id) => ({
  type: FETCH_TASK,
  payload: id,
});

export const patchTask = (id, data) => {
  return {
    type: PATCH_TASK,
    payload: { id, data },
  };
};

export const patchTaskSuccess = (task) => ({
  type: PATCH_TASK_SUCCESS,
  payload: task,
});

export const patchTaskFailed = (error) => ({
  type: PATCH_TASK_FAILED,
  payload: error,
});

export const createTask = (data) => ({
  type: CREATE_TASK,
  payload: data,
});

export const deleteTask = (id) => ({
  type: DELETE_TASK,
  payload: id,
});

export const deleteTaskSuccess = (id) => ({
  type: DELETE_TASK_SUCCESS,
  payload: id,
});

export const deleteTaskFailed = (error) => ({
  type: DELETE_TASK_FAILED,
  payload: error,
});
