import React from "react";
import "moment/locale/pt-br";
import styled from "styled-components";
import classnames from "classnames";

import { checkinTypes } from "../common/text/CheckinType";
import Icon from "../common/Icon";

const TagDiv = styled.div`
  display: inline;
  border-radius: 4px;
  padding: 0px 6px;
  color: white;
  span {
    font-size: 12px;
    font-weight: 500;
  }
  .checkin-type {
    color: #313c6d;
  }
`;
export default function Tag({ color, text, className }) {
  return (
    <TagDiv
      className={classnames(className, "m:.1")}
      style={{ backgroundColor: color }}
    >
      <span>{text}</span>
    </TagDiv>
  );
}
// reutiliza o css das tags para o tipo
export function Type({ text, ...props }) {
  // procura o objeto no qual o valor é igual ao tipo do checkin
  let type = Object.values(checkinTypes).find(
    (checkin) => checkin.value == text
  );
  return (
    <TagDiv style={{ backgroundColor: "#A6B3F7" }} {...props}>
      <span className="checkin-type">
        <nobr>
          <Icon type={type.icon.props.type} className="m-r:.5" /> {type.label}
        </nobr>
      </span>
    </TagDiv>
  );
}
