import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Form, message, Popover } from "antd";

import Icon from "../common/Icon";
import Button from "../common/Button";
import api from "../../services/api";
import RemoveAlert from "../common/RemoveAlert";

import ModalForm from "../common/ModalForm";
import InputField from "../common/forms/InputField";
import { requiredRule } from "../../utils/form";

export const PerfilDiv = styled(Link)`
  width: 36px;
  height: 36px;
  background-color: #a6b3f7;
  border-radius: 8px;
  font-size: 20px;
  color: #313c6d;
`;
const ColorSquare = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;
const SquareDiv = styled.div`
  width: 35px;
  height: 35px;
  margin: 9px 0px 0px 9px;
  cursor: pointer;
  .menu-item.active {
    border: 2px solid;
    border-radius: 50%;
  }
  .menu-item {
    height: 100%;
    padding: 5px;
  }
`;
const ColorHolder = styled.div`
  width: 30px;
  height: 30px;
`;

const avatarColors = [
  "#CA3131",
  "#ED0080",
  "#E417E9",
  "#830CAD",
  "#1C51B8",
  "#11A4C5",
  "#06A86E",
  "#56B00F",
  "#D6A80A",
  "#FA5D05",
  "#7C7E7E",
  "#324448",
];

export default function ManageTags({ hideModal, tags, mutate, ...props }) {
  const [loading, setLoading] = useState();
  const [form] = Form.useForm();

  const tagsObject = (tags || []).reduce(
    (cur, { id, ...rest }) => ({
      ...cur,
      [id]: rest,
    }),
    {}
  );

  const handleFinish = async (values) => {
    if (loading) return;
    setLoading(true);
    const createOperations = [];
    const patchOperations = [];
    let deleteOperations = [];
    const oldItems = [];

    for (let { id, ...item } of values.tags || []) {
      if (!id) createOperations.push(api.post("/tags/", item));
      else {
        oldItems.push(id);
        if (
          item.name !== tagsObject[id].name ||
          item.color !== tagsObject[id].color
        )
          patchOperations.push(api.patch(`/tags/${id}/`, item));
      }
    }
    deleteOperations = Object.keys(tagsObject)
      .filter((key) => !oldItems.includes(parseInt(key)))
      .map((id) => api.delete(`/tags/${id}/`));

    try {
      await Promise.all([
        ...createOperations,
        ...patchOperations,
        ...deleteOperations,
      ]);
      hideModal();
      mutate();
      message.success("Alterações salvas com sucesso");
    } catch {
      message.success("Erro ao realizar algumas operações");
    }
    setLoading(false);
  };

  return (
    <>
      <ModalForm
        loading={loading}
        onSubmit={form.submit}
        onHide={hideModal}
        newItem={true}
        contentType={"Gerenciar tags"}
        className={"tag"}
        footerText="Confirmar"
        {...props}
      >
        {tags && (
          <Form form={form} initialValues={{ tags }} onFinish={handleFinish}>
            <Form.List name="tags">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <div key={field.key} className="w:full d:f m-b:.8 a-i:c">
                      <Form.Item
                        noStyle
                        name={[field.name, "color"]}
                        preserve
                      />
                      <div>
                        <Form.Item noStyle name={[field.name, "color"]}>
                          <ColorSelector />
                        </Form.Item>
                      </div>
                      <div className="w:full m-x:1">
                        <Form.Item
                          noStyle
                          name={[field.name, "name"]}
                          rules={[requiredRule]}
                        >
                          <InputField />
                        </Form.Item>
                      </div>
                      <div>
                        <Button
                          type="white"
                          shape="circle"
                          icon="delete"
                          onClick={() => remove(field.name)}
                        />
                      </div>
                    </div>
                  ))}

                  <div className="m-t:3">
                    <Button
                      className="btn btn-dashed w:full "
                      onClick={() => add({ color: avatarColors[0] })}
                    >
                      <Icon type="plus" />
                      Adicionar mais um item
                    </Button>
                  </div>
                </>
              )}
            </Form.List>
          </Form>
        )}
      </ModalForm>
    </>
  );
}

function ColorSelector({ onChange, value }) {
  return (
    <Popover
      overlayStyle={{ width: 220 }}
      placement="right"
      trigger="click"
      content={
        <div className="d:f flx-w:w">
          {avatarColors.map((color) => (
            <SquareDiv key={color} onClick={() => onChange(color)}>
              <div
                className={`menu-item ${value === color ? "active" : ""}`}
                style={{ borderColor: `${color}` }}
              >
                <ColorSquare style={{ backgroundColor: `${color}` }} />
              </div>
            </SquareDiv>
          ))}
        </div>
      }
    >
      <ColorHolder>
        <ColorSquare style={{ backgroundColor: `${value}` }} />
      </ColorHolder>
    </Popover>
  );
}
