import moment from "moment";

export const checkinsPeriodFilter = (array, { init, end }) => {
  const initDate = moment(init, "YYYY-MM-DD");
  const endDate = moment(end, "YYYY-MM-DD");
  return array.filter((workflow) => {
    if (workflow.weekday !== undefined) {
      const weekday = moment(workflow.weekday, "YYYY-MM-DD");
      return initDate.isSameOrBefore(weekday) && endDate.isSameOrAfter(weekday);
    } else return false;
  });
};

export const paramsFilter = (array, propParams) => {
  const params = Object.keys(propParams).reduce(
    (acc, k) =>
      propParams[k] !== undefined ? { [k]: propParams[k], ...acc } : acc,
    {}
  );

  return array.filter((item) => {
    return Object.keys(params).reduce((acc, k) => {
      switch (k) {
        case "members":
          return (
            acc &&
            (item["target_type"] === "COMPANY" ||
              item["members"]?.reduce(
                (accu, t) =>
                  accu ||
                  params["members"]?.reduce(
                    (accum, id) => accum || id === t,
                    false
                  ),
                false
              ))
          );
        default:
          return acc && item[k] === params[k];
      }
    }, true);
  });
};
