import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form } from "antd";

import Header from "../../components/onboarding/OnboardingHeader";
import axios from "axios";
import { useUser } from "../../context/UserContext";
import StepButtons from "../../components/common/Button/StepButton";
import { displayGenericError } from "../../utils/messages";
import UserOnboarding from "../../views/onboarding/UserOnboarding";
import { StyledDiv } from "./StyledPage";

export default function Onboarding() {
  const [user, updateUser] = useUser();
  const history = useHistory();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [step, setStep] = useState(0);

  //prosegue para a proxima etapa, e caso seja a ultima, finaliza o onboarding
  const handleContinue = async () => {
    if (step === 2) {
      try {
        await axios.patch(`/companies/members/${user.member_id}/`, {
          onboarding_finished: true,
        });
        updateUser();
        history.push("/");
      } catch {
        displayGenericError();
      }
    } else {
      setStep(step + 1);
    }
  };
  const handleBack = () => setStep(step - 1);
  let headerKey = undefined;
  if (step === 1) headerKey = 1;
  else if (step === 2) headerKey = 2;
  return (
    <StyledDiv>
      <Header headerKey={headerKey} />
      <div className="m:a stepDiv">
        <div className="t-a:c">
          {/* form com cada etapa do onboarding  */}
          <UserOnboarding form={form} user={user} step={step} />
        </div>
      </div>
      {/*botões que controlam as etapas do onboarding  */}
      <StepButtons
        handleBack={handleBack}
        handleContinue={handleContinue}
        disableBack={[0].includes(step)}
      />
    </StyledDiv>
  );
}
