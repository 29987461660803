// @flow
import { all, fork, put, takeEvery } from "redux-saga/effects";

import * as constants from "./constants";
import * as workflowsConstants from "../workflows/constants";
import api from "../../services/api";

function* fetchOcurrencesInResponse({ payload }) {
  const params = {
    member: payload.author.id,
    date: payload.date,
  };
  try {
    const { data: workflows } = yield api.get("/workflows/member_ocurrences/", {
      params,
    });
    yield put({
      type: workflowsConstants.LOAD_OCURRENCES_PARAM_SUCCESS,
      payload: workflows,
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: workflowsConstants.LOAD_OCURRENCES_PARAM_FAILURE,
      payload: params,
    });
  }
}

export function* watchCreateCheckin() {
  yield takeEvery(constants.CREATE_ITEM_SUCCESS, fetchOcurrencesInResponse);
}

function* checkinsSaga() {
  yield all([fork(watchCreateCheckin)]);
}

export default checkinsSaga;
