import React from "react";

import Icon from "./Icon";

var classNames = require("classnames");

export function CardContent({
  icon,
  title,
  extra,
  collapsed = false,
  children,
  ...props
}) {
  return (
    <div {...props}>
      <div className="d:f flx-d:r">
        <h6 className="text-darkviolet-sec m-b:0 d:i-b">
          {icon && <Icon type={icon} className="m-r:.8" />} {title}
        </h6>
        {extra && <div className="m-l:a">{extra}</div>}
      </div>
      {!collapsed && <div className="m-t:.8">{children}</div>}
    </div>
  );
}

const Card = ({
  hasPadding = true,
  outlined = false,
  children,
  className,
  bodyClassName,
  ...props
}) => {
  const cardClassName = classNames({
    card: true,
    [className]: className,
    "--outlined": outlined,
  });
  const cardBodyClassName = classNames({
    "card-body": true,
    [bodyClassName]: bodyClassName,
  });
  return (
    <div className={cardClassName} {...props}>
      {hasPadding ? (
        <div className={cardBodyClassName}>{children}</div>
      ) : (
        children
      )}
    </div>
  );
};

export default Card;
