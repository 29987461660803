import React from "react";
import useSWR from "swr";

import { get } from "../../../services/api";
import SelectField from "./SelectField";

export default function MemberSelect({ ...props }) {
  const { data } = useSWR(
    props.teamId
      ? `/companies/members/?teams=${props.teamId}`
      : `/companies/members/`,
    get,
    {
      revalidateOnFocus: false,
    }
  );
  return (
    <SelectField
      optionFilterProp="label"
      {...props}
      options={(data || []).map((m) => ({
        value: m.id,
        label: m.name,
      }))}
    />
  );
}
