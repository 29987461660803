import pick from "lodash/pick";

export function mergeDistinct(arr1, arr2, key) {
  const arr = [];
  const helper = [];
  arr1.forEach((item) => {
    arr.push(item);
    helper.push(item[key]);
  });
  arr2.forEach((item) => {
    !helper.includes(item[key]) && arr.push(item);
  });
  return arr;
}

export function diffChecker(partialObj, fullObj) {
  const neededKeys = Object.keys(partialObj);
  let partialFromFull = pick(fullObj, neededKeys);
  partialObj = JSON.stringify(partialObj);
  partialFromFull = JSON.stringify(partialFromFull);
  return partialObj != partialFromFull;
}
