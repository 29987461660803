import styled from "styled-components";

const PriorityBody = styled.div`
  background: #f1f3fe;
  flex-grow: 1;
  border-radius: 3px;
  padding: 6px 14px;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4b61e9;
  }
`;

export default PriorityBody;
