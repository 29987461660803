// @flow
import { all } from "redux-saga/effects";
// import authSaga from "./ideas/saga";
import ideasSaga from "./ideas/saga";
import goalsSaga from "./goals/saga";
import stepsSaga from "./steps/saga";
import tasksSaga from "./tasks/saga";
import keyresultsSaga from "./key_results/saga";
import checkinsSaga from "./checkins/saga";

export default function* rootSaga(getState) {
  yield all([
    // authSaga(),
    ideasSaga(),
    goalsSaga(),
    stepsSaga(),
    tasksSaga(),
    keyresultsSaga(),
    checkinsSaga(),
  ]);
}
