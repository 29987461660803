import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Menu } from "antd";
import useSWR from "swr";
import styled from "styled-components";
import qs from "querystringify";
import { useTranslation } from "react-i18next";

import Card from "../common/Card";
import Icon from "../common/Icon";
import { get } from "../../services/api";
import { Loader } from "./../common/PageLoader";
import { useUser } from "../../context/UserContext";
import PrioritiesList from "./PrioritiesList";

export function WeeklyPriorities({
  date,
  initialFilter = {
    organization: false,
    personal: false,
    teams: [],
  },
  dataSource,
}) {
  const { data: teams } = useSWR("/teams/", get);
  const [{ member_id }] = useUser();
  const [filter, setFilter] = useState(initialFilter);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialFilter) setFilter(initialFilter);
  }, [initialFilter]);

  const week_init_date_lte = date.clone().endOf("week").format("YYYY-MM-DD");
  const week_init_date_gte = date.clone().startOf("week").format("YYYY-MM-DD");
  const query = qs.stringify({
    type_in: [
      filter.organization && "COMPANY",
      filter.personal && "MEMBER",
      filter.teams && filter.teams.length > 0 && "TEAM",
    ].filter(Boolean),
    member_or: member_id,
    team_in_or: filter.teams,
    week_init_date_lte,
    week_init_date_gte,
  });

  const { data, error } = useSWR(`/weekly_priorities/?${query}`, get, {
    revalidateOnFocus: false,
  });
  const loading = !(data || error);
  return (
    <Card>
      <div className="d:f m-b:1 a-i:c">
        <h5 className="text-darkviolet-sec">
          <Icon type="focus" /> {t("weeklypriorities.label")}
          <span className="m-l:.5 f:1">({(data || dataSource)?.length})</span>
        </h5>
        <div className="m-l:a">
          <Filter value={filter} onChange={setFilter} teams={teams} />
        </div>
      </div>

      {loading ? (
        <div className="t-a:c p-y:9">
          <Loader />
        </div>
      ) : (
        <PrioritiesList data={data || dataSource} teams={teams} />
      )}
    </Card>
  );
}

const FilterMenu = styled(Menu)`
  & > *:not(: first-child) {
    border-top: 1px solid #dddee9;
  }
  .ant-checkbox-inner {
    border-radius: 100%;
  }
`;

function Filter({ onChange, teams, value }) {
  const handleChange = ({ key }) => {
    const newValue = { ...value };
    const teamId = parseInt(key);
    if (!!teamId) {
      if (newValue.teams.includes(teamId))
        newValue.teams = newValue.teams.filter((i) => i !== teamId);
      else newValue.teams.push(teamId);
    } else {
      if (key === "MEMBER") newValue.personal = !newValue.personal;
      if (key === "COMPANY") newValue.organization = !newValue.organization;
    }
    onChange(newValue);
  };

  const overlay = (
    <FilterMenu onClick={handleChange} style={{ width: 200 }}>
      <Menu.Item key="COMPANY">
        <Icon type="earth" className="m-r:.8" />
        Organização
        <Checkbox className="fl:r" checked={value.organization} />
      </Menu.Item>
      {(teams || []).map(({ name, id }) => (
        <Menu.Item key={id}>
          <Icon type="team" className="m-r:.8" />
          {name}
          <Checkbox
            className="fl:r"
            checked={value.teams && value.teams.includes(id)}
          />
        </Menu.Item>
      ))}
      <Menu.Item key="MEMBER">
        <Icon type="accountCircle" className="m-r:.8" />
        Meus focos
        <Checkbox className="fl:r" checked={value.personal} />
      </Menu.Item>
    </FilterMenu>
  );

  return (
    <Dropdown overlay={overlay} trigger="click">
      <button className="btn-no-style">
        <Icon type="filter" />
      </button>
    </Dropdown>
  );
}
