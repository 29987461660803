import React from "react";
import { Layout } from "antd";

import Sidebar from "../../components/common/Sidebar";

const { Content } = Layout;

function TrialMessage({ daysTrial }) {
  return (
    <div
      css={`
        background: #616471;
        color: white;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
      `}
      className="container-fluid text-center"
    >
      {daysTrial !== 0
        ? `${daysTrial > 1 ? `Faltam ${daysTrial} dias` : "Falta um dia"} para
      seu período de avaliação acabar.`
        : `Seu perído de avaliação acaba hoje.`}{" "}
      <a href="/cadastrar/" className="text-white">
        <u>Fale conosco</u>
      </a>
    </div>
  );
}

function DashboardView({ routes, inTrial, daysTrial }) {
  return (
    <Layout>
      <Layout>
        <Sidebar />
        <Content>
          <div className="document-body--sidenav-on">
            {inTrial && daysTrial && <TrialMessage daysTrial={daysTrial} />}
            {routes}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default DashboardView;
