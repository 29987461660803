// @flow
import { all, fork, put, takeEvery } from "redux-saga/effects";
import Axios from "axios";
import { push, goBack } from "connected-react-router";

import {
  FETCH_TASKS,
  PATCH_TASK,
  CREATE_TASK,
  FETCH_TASK,
  DELETE_TASK,
} from "./constants";

import {
  fetchTasksFailed,
  fetchTasksSuccess,
  patchTaskFailed,
  patchTaskSuccess,
  deleteTaskSuccess,
  deleteTaskFailed,
} from "./actions";
import { FETCH_STEPS_SUCCESS, DELETE_STEP } from "../steps/constants";

const url = "/api/tarefas/";

function* tasks({ payload }) {
  try {
    const { data } = yield Axios.get(url, {
      params: payload,
    });
    yield put(fetchTasksSuccess(data));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(fetchTasksFailed(message));
  }
}

function* patchTask({ payload: { data, id } }) {
  try {
    const { data: responseData } = yield Axios.patch(`${url}${id}/`, data);
    yield put(patchTaskSuccess(responseData));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(patchTaskFailed(message));
  }
}

function* task({ payload }) {
  try {
    const { data } = yield Axios.get(`${url}${payload}/`);
    yield put(fetchTasksSuccess([data]));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(fetchTasksFailed(message));
  }
}

function* createTask({ payload }) {
  try {
    const { data } = yield Axios.post(url, payload);
    yield put(fetchTasksSuccess([data]));
    yield put(push({ pathname: `/tarefas/${data.id}` }));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(fetchTasksFailed(message));
  }
}

function* deleteTask({ payload: id }) {
  try {
    yield Axios.delete(`${url}${id}/`);
    yield put(goBack());
    yield put(deleteTaskSuccess(id));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = error;
    }
    yield put(deleteTaskFailed(message));
  }
}

function* interceptSteps({ payload }) {
  yield put(
    fetchTasksSuccess(
      payload.reduce((acc, { tasks }) => [...acc, ...tasks], [])
    )
  );
}

export function* watchFetchTasks() {
  yield takeEvery(FETCH_TASKS, tasks);
}

export function* watchFetchTask() {
  yield takeEvery(FETCH_TASK, task);
}

export function* watchPatchTask() {
  yield takeEvery(PATCH_TASK, patchTask);
}

export function* watchCreateTask() {
  yield takeEvery(CREATE_TASK, createTask);
}

export function* watchFetchStep() {
  yield takeEvery(FETCH_STEPS_SUCCESS, interceptSteps);
}

export function* watchDeleteTask() {
  yield takeEvery(DELETE_TASK, deleteTask);
}

function* tasksSaga() {
  yield all([
    fork(watchFetchTasks),
    fork(watchFetchTask),
    fork(watchFetchStep),
    fork(watchCreateTask),
    fork(watchPatchTask),
    fork(watchDeleteTask),
  ]);
}

export default tasksSaga;
