import * as filters from "./filters";

// Componente para compor seletores e filtros genéricos
export const filteredSelector = (selector, filter, filterValue) => (state) =>
  filter(selector(state), filterValue);

// Seletores comuns
export const checkinsSelector = (state) => state.Checkins.entities;

export const checkinsValueSelector = (state) =>
  Object.values(checkinsSelector(state));

export const checkinItemSelector = (id) => (state) =>
  checkinsSelector(state)[id];

// Seletores filtrados
export const checkinsExactDateSelector = (propDate) =>
  filteredSelector(
    checkinsValueSelector,
    filters.checkinsExactDateFilter,
    propDate
  );

export const checkinsExactDateFilteredSelector = ({ date, ...params }) =>
  filteredSelector(checkinsExactDateSelector(date), filters.paramsFilter, {
    ...params,
  });

export const checkinsPeriodSelector = ({ init, end }) =>
  filteredSelector(checkinsValueSelector, filters.checkinsPeriodFilter, {
    init,
    end,
  });

export const checkinsPeriodFilteredSelector = ({ init, end, ...params }) =>
  filteredSelector(
    checkinsPeriodSelector({ init, end }),
    filters.paramsFilter,
    { ...params }
  );
