import {
  FETCH_IDEAS,
  FETCH_IDEAS_SUCCESS,
  FETCH_IDEAS_FAILED,
  FETCH_IDEA,
  PATCH_IDEA,
  PATCH_IDEA_SUCCESS,
  PATCH_IDEA_FAILED,
  CREATE_IDEA,
  DELETE_IDEA,
  DELETE_IDEA_FAILED,
  DELETE_IDEA_SUCCESS,
} from "./constants";

export const fetchIdeas = (params) => ({
  type: FETCH_IDEAS,
  payload: params,
});

export const fetchIdeasSuccess = (goals) => ({
  type: FETCH_IDEAS_SUCCESS,
  payload: goals,
});

export const fetchIdeasFailed = (error) => ({
  type: FETCH_IDEAS_FAILED,
  payload: error,
});

export const fetchIdea = (params) => ({
  type: FETCH_IDEA,
  payload: params,
});

export const patchIdea = (id, data) => ({
  type: PATCH_IDEA,
  payload: { id, data },
});

export const patchIdeaSuccess = (idea) => ({
  type: PATCH_IDEA_SUCCESS,
  payload: idea,
});

export const patchIdeaFailed = (error) => ({
  type: PATCH_IDEA_FAILED,
  payload: error,
});

export const createIdea = (data) => ({ type: CREATE_IDEA, payload: data });

export const deleteIdea = (id) => ({
  type: DELETE_IDEA,
  payload: id,
});

export const deleteIdeaSuccess = (id) => ({
  type: DELETE_IDEA_SUCCESS,
  payload: id,
});

export const deleteIdeaFailed = (error) => ({
  type: DELETE_IDEA_FAILED,
  payload: error,
});
