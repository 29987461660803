import React, { Suspense } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import UserProvider from "../providers/UserProvider";
import DashboardRouter from "./DashboardRouter";
import routes, { modalRoutes } from "../lib/routes";
import PageLoader from "../components/common/PageLoader";
import FilterProvider from "../providers/FilterProvider";

function MainRouter() {
  const { isRegular, lastRegularLocation } = useSelector(
    ({ Location }) => Location
  );

  return (
    <UserProvider>
      <FilterProvider>
        <Suspense fallback={<PageLoader />}>
          {modalRoutes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
          <Switch location={isRegular ? undefined : lastRegularLocation}>
            {routes
              .filter((route) => route.fullPage)
              .map((route) => (
                <Route key={route.path} {...route} />
              ))}
            <Route path="/" component={DashboardRouter} />
          </Switch>
        </Suspense>
      </FilterProvider>
    </UserProvider>
  );
}

export default withRouter(MainRouter);
