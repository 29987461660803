import moment from "moment";

export const checkinsExactDateFilter = (array, propDate) =>
  array.filter(({ date }) => {
    const itemDate = moment(date, "YYYY-MM-DD");
    const targetDate = moment(propDate, "YYYY-MM-DD");
    return itemDate.isSame(targetDate);
  });

export const checkinsPeriodFilter = (array, { init, end }) => {
  if (!!init && !!end) {
    const initDate = moment(init, "YYYY-MM-DD");
    const endDate = moment(end, "YYYY-MM-DD");
    return array.filter(({ date }) => {
      const itemDate = moment(date, "YYYY-MM-DD");
      return (
        itemDate.isSameOrAfter(initDate) && itemDate.isSameOrBefore(endDate)
      );
    });
  } else if (!!init) {
    const initDate = moment(init, "YYYY-MM-DD");
    return array.filter(({ date }) => {
      const itemDate = moment(date, "YYYY-MM-DD");
      return itemDate.isSameOrAfter(initDate);
    });
  } else {
    const endDate = moment(end, "YYYY-MM-DD");
    return array.filter(({ date }) => {
      const itemDate = moment(date, "YYYY-MM-DD");
      return itemDate.isSameOrBefore(endDate);
    });
  }
};

export const paramsFilter = (array, propParams) => {
  const params = Object.keys(propParams).reduce(
    (acc, k) =>
      propParams[k] !== undefined ? { [k]: propParams[k], ...acc } : acc,
    {}
  );
  return array.filter((item) => {
    return Object.keys(params).reduce((acc, k) => {
      switch (k) {
        case "author":
          return acc && item[k].id === params[k];
        case "team":
          return (
            acc &&
            item["author"]?.teams?.reduce(
              (accu, t) => accu || t === params[k],
              false
            )
          );
        default:
          return acc && item[k] === params[k];
      }
    }, true);
  });
};
