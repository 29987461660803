// @flow
import {
  FETCH_GOALS,
  FETCH_GOALS_SUCCESS,
  FETCH_GOALS_FAILED,
  FETCH_GOAL,
  SET_COMPANY_GOALS,
  PATCH_GOAL,
  PATCH_GOAL_SUCCESS,
  PATCH_GOAL_FAILED,
  CREATE_GOAL,
  DELETE_GOAL,
  DELETE_GOAL_FAILED,
  DELETE_GOAL_SUCCESS,
} from "./constants";
import { mergeDistinct } from "../../utils";

const INIT_STATE = {
  goals: [],
  loaded: false,
  loading: false,
  companyGoalsLoaded: false,
};

const Goals = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_GOALS:
      return { ...state, loading: true, loaded: false };
    case FETCH_GOALS_SUCCESS:
      return {
        ...state,
        goals: mergeDistinct(state.goals, action.payload, "id"),
        loading: false,
        error: null,
        loaded: true,
      };
    case FETCH_GOALS_FAILED:
      return { ...state, error: action.payload, loading: false, loaded: true };

    case FETCH_GOAL:
      return { ...state, loading: true, loaded: false };

    case SET_COMPANY_GOALS:
      return { ...state, companyGoalsLoaded: true };

    case PATCH_GOAL:
      return { ...state, loading: true };
    case PATCH_GOAL_SUCCESS:
      const goals = [...state.goals];
      const index = goals.findIndex(({ id }) => id === action.payload.id);
      goals[index] = action.payload;
      return { ...state, goals };
    case PATCH_GOAL_FAILED:
      return { ...state };

    case CREATE_GOAL:
      return { ...state, loading: true };

    case DELETE_GOAL:
      return { ...state, loading: true, loaded: false };
    case DELETE_GOAL_SUCCESS:
      return {
        ...state,
        goals: state.goals.filter(({ id }) => id !== action.payload),
      };
    case DELETE_GOAL_FAILED:
      return { ...state, error: action.payload, loading: false, loaded: true };

    default:
      return { ...state };
  }
};

export default Goals;
