import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";

import { FilterContext } from "../context/FilterContext";
import { useCompany } from "../context/UserContext";

export default function FilterProvider({ children }) {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [timeFilter, setTimeFilter] = useState({
    value: undefined,
    type: "day",
  });

  const date = queryParams.get("date")
    ? moment(queryParams.get("date"), "YYYY-MM-DD")
    : moment();

  const handleChangeDate = (d) => {
    queryParams.set("date", d.format("YYYY-MM-DD"));
    history.push({
      search: queryParams.toString(),
    });
  };

  const [{ workdays }] = useCompany();
  // dias possíveis em cada combinação do filtro de dias de trabalho do backend
  const workdaysFilter = {
    SUN: [0],
    SAT: [6],
    "SAT+SUN": [6, 0],
    WEEK: [1, 2, 3, 4, 5],
    "WEEK+SUN": [0, 1, 2, 3, 4, 5],
    "WEEK+SAT": [1, 2, 3, 4, 5, 6],
    "WEEK+SAT+SUN": [0, 1, 2, 3, 4, 5, 6],
  };

  const getAmountToAdd = (day, amount) => {
    // usando essa função pois o operador % em js não dá resultados naturais para números negativos
    const naturalMod = (n, m) => ((n % m) + m) % m;

    var amountToAdd = amount;
    if (workdays !== undefined) {
      while (
        !workdaysFilter[workdays].includes(naturalMod(day + amountToAdd, 7))
      ) {
        amountToAdd += amount < 0 ? -1 : 1;
      }
      return amountToAdd;
    }
    return 0;
  };

  // VISÃO DIÁRIA
  const daysFilter = {
    days: [],
    minified: workdaysFilter[workdays].length > 5,
  };

  let weekInit = date.clone().startOf("week");
  if (!workdaysFilter[workdays].includes(weekInit.day()))
    weekInit = weekInit.clone().add(getAmountToAdd(weekInit.day(), 1), "days");

  for (let d of [...Array(7).keys()]) {
    const day = weekInit.clone().add(d, "days");
    if (workdaysFilter[workdays].includes(day.day())) {
      daysFilter.days.push({
        value: day,
        isSelected: day.isSame(date, "day"),
      });
    }
  }

  // VISÃO SEMANAL
  const weeksFilter = {
    weeks: [],
  };

  let monthInit = date.clone().startOf("month");

  if (!workdaysFilter[workdays].includes(monthInit.day()))
    monthInit = monthInit
      .clone()
      .add(getAmountToAdd(monthInit.day(), 1), "days");

  for (let w of [...Array(5).keys()]) {
    const week = monthInit.clone().add(w, "weeks");
    weeksFilter.weeks.push({
      value: week,
      isSelected: week.isSame(date, "week"),
    });
  }

  return (
    <FilterContext.Provider
      value={{
        date,
        handleChangeDate,
        daysFilter,
        weeksFilter,
        workdaysFilter: workdays ? workdaysFilter[workdays] : undefined,
        timeFilter,
        setTimeFilter,
        getAmountToAdd,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}
