export const FETCH_TASKS = "FETCH_TASKS";
export const FETCH_TASKS_SUCCESS = "FETCH_TASKS_SUCCESS";
export const FETCH_TASKS_FAILED = "FETCH_TASKS_FAILED";

export const FETCH_TASK = "FETCH_TASK";

export const PATCH_TASK = "PATCH_TASK";
export const PATCH_TASK_SUCCESS = "PATCH_TASK_SUCCESS";
export const PATCH_TASK_FAILED = "PATCH_TASK_FAILED";

export const CREATE_TASK = "CREATE_TASK";

export const DELETE_TASK = "DELETE_TASK";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAILED = "DELETE_TASK_FAILED";
