import React, { useEffect, useState } from "react";
import { Form, message, Input, DatePicker } from "antd";

import api from "../../services/api";
import { displayGenericError } from "../../utils/messages";
import ModalForm from "../common/ModalForm";
import Label from "../common/forms/Label";
import { requiredRule } from "../../utils/form";
import Icon from "../common/Icon";

export default function AddShortcutModal({
  visible,
  onHide,
  onSuccess,
  type,
  obj_id,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await api.post("/integrations/shortcuts/", {
        link: values.link,
        checkin: type === "CHECKIN" ? obj_id : undefined,
        weekly_priority: type === "WEEKLY_PRIORITY" ? obj_id : undefined,
      });
      message.success("Atalho criado com sucesso!");

      onHide();
      onSuccess();
      form.resetFields();
    } catch (e) {
      console.log(e);
      displayGenericError(e.message);
    }
    setLoading(false);
  };

  return (
    <ModalForm
      contentType={"Integrações"}
      newItem
      onSubmit={form.submit}
      visible={visible}
      onCancel={onHide}
      onHide={onHide}
      footerText={"Adicionar"}
      loading={loading}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item
          label={<Label>{"Link"}</Label>}
          name="link"
          rules={[requiredRule]}
        >
          <Input prefix={<Icon type="link" />} placeholder="Insira uma url" />
        </Form.Item>
      </Form>
    </ModalForm>
  );
}
