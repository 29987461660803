export const requiredRule = { message: "Campo obrigatório", required: true };
export const emailRule = {
  type: "email",
  message: "Esse email não é válido!",
};
export const emailRules = [emailRule, requiredRule];
export const confirmPasswordRules = (password_label) => {
  return [
    requiredRule,
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue(password_label) === value) {
          return Promise.resolve();
        }
        return Promise.reject("As senhas devem ser iguais!");
      },
    }),
  ];
};
export const agreementRule = {
  validator: (_, value) =>
    value
      ? Promise.resolve()
      : Promise.reject(
          "Você deve aceitar os termos para usufruir da aplicação!"
        ),
};

export const mapErrors = (form, data) =>
  form.setFields(
    Object.keys(data).map((k) => ({
      name: k,
      errors: Array.isArray(data[k]) ? data[k] : [data[k]],
    }))
  );
