import React, { useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Card from "../../common/Card";
import PersonalCheckins from "../PersonalCheckins";
import { useUser } from "../../../context/UserContext";
import { AlertCircle } from "react-feather";
import trophy from "../../../svgs/trophy.svg";
import { checkinsPeriodFilteredSelector } from "../../../redux/checkins/selectors";
import { fetchCheckins } from "../../../redux/checkins/actions";

export default function PendingCheckins() {
  const { t } = useTranslation();
  const [user] = useUser();
  const yesterday = moment().add(-1, "day").format("YYYY-MM-DD");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchCheckins({
        author: user.member_id,
        date_lte: yesterday,
        finished: false,
      })
    );
  }, [dispatch, user.member_id, yesterday]);

  const pendingCheckins = useSelector(
    checkinsPeriodFilteredSelector({
      end: yesterday,
      author: user.member_id,
      finished: false,
    })
  );

  const dates = {};
  for (const item of pendingCheckins) {
    if (dates[item.date]) dates[item.date].push(item);
    else dates[item.date] = [item];
  }

  return (
    <Card>
      <h5 className="text-darkviolet-sec m-b:1">
        <AlertCircle color={`red`} className="m-r:.5" />
        {t("overdue.label")}
        <span className="m-l:.5 f:1">({pendingCheckins?.length})</span>
      </h5>
      {pendingCheckins?.length > 0 ? (
        Object.keys(dates)
          .sort((a, b) => moment(b, "YYYY-MM-DD").diff(moment(a, "YYYY-MM-DD")))
          .map((date) => {
            const dateObj = moment(date);
            return (
              <div className="m-b:1" key={date}>
                <h6 className="t-t:c">
                  {dateObj.format("dddd")} · {dateObj.format("DD [de] MMMM")}
                </h6>
                <PersonalCheckins
                  data={dates[date]}
                  date={moment(date, "YYYY-MM-DD")}
                  minified
                />
              </div>
            );
          })
      ) : (
        <div className="d:f flx-d:c m-b:1 a-i:c">
          <span className="f:10">
            <img src={trophy} />
          </span>
          <span className="text-darkviolet-sec">{t("overdue-empty")}</span>
        </div>
      )}
    </Card>
  );
}
