import React from "react";
import classnames from "classnames";

import PriorityBody from "../weekly-priorities/PriorityBody";

import TrailingIcons from "../common/TrailingIcons/index";
import Icon from "../common/Icon";

export default function WorkflowAction({ data }) {
  return (
    <div className="d:f a-i:c">
      <PriorityBody className="d:f flx-d:r pos:r a-i:b">
        <div
          className={classnames(
            "m-r:.8",
            data?.completed ? "text-primary" : undefined
          )}
        >
          <Icon type="calendarClock" />
        </div>
        <div className="d:f flx-d:c a-i:f-s">
          <h6
            className={classnames(
              "d:i-b f-w:400 f:1 m-b:0",
              data?.completed ? "t-d:l-t text-gray" : undefined
            )}
            style={{
              lineHeight: 2,
              color: data?.completed ? "blue" : undefined,
            }}
          >
            {data?.name}
          </h6>
        </div>
      </PriorityBody>
    </div>
  );
}
