import React, { lazy } from "react";

import { withRoleRoute } from "../components/withPrivateRoute";
import { Switch, Route } from "react-router-dom";

const Teams = lazy(() => import("../pages/management/departments/list"));
const Team = lazy(() => import("../pages/management/departments/edit"));
const Members = lazy(() => import("../pages/management/members"));
const CompanySettings = lazy(() =>
  import("../pages/settings/CompanySettingsContainer")
);

function renderRoutes(routes) {
  return (
    <Switch>
      {routes.map((route) => (
        <Route {...route} key={route.path} />
      ))}
    </Switch>
  );
}

export const modalRoutes = [
  {
    path: "/focos-da-semana/criar",
    component: lazy(() => import("../pages/weekly-priorities/create")),
  },
  {
    path: "/checkins/criar",
    component: lazy(() => import("../pages/check-ins/create")),
  },
  {
    path: "/workflows/criar",
    component: lazy(() => import("../pages/workflows/create")),
  },
  {
    path: "/workflows/:id(\\d+)",
    component: lazy(() => import("../pages/workflows/edit")),
  },
  {
    path: "/objetivos/criar",
    component: lazy(() => import("../pages/goals/edit")),
  },
  {
    path: "/objetivos/:id(\\d+)",
    component: lazy(() => import("../pages/goals/edit")),
    exact: true,
  },
  {
    path: "/objetivos/:goal_id(\\d+)/criar/resultado-chave",
    component: lazy(() => import("../pages/key-results/edit")),
    // exact: true,
  },
  {
    path: "/objetivos/resultado-chave/:id(\\d+)",
    component: lazy(() => import("../pages/key-results/edit")),
    exact: true,
  },

  // {
  //   path: "/ideias/:id(\\d+)",
  //   component: lazy(() => import("../pages/ideaPreview")),
  //   exact: true,
  // },
  // {
  //   path: "/etapas/:id(\\d+)",
  //   component: lazy(() => import("../pages/stepPreview")),
  //   exact: true,
  // },
  // {
  //   path: "/tarefas/criar",
  //   component: lazy(() => import("../pages/task")),
  // },
  // {
  //   path: "/tarefas/:id(\\d+)",
  //   component: lazy(() => import("../pages/taskPreview")),
  //   exact: true,
  // },
  {
    path: "/administracao/times/criar",
    component: withRoleRoute(Team, ["ADMIN"]),
  },
  {
    path: "/administracao/times/:department_id(\\d+)",
    component: withRoleRoute(Team, ["ADMIN"]),
  },
  // {
  //   path: "/ideias/:ideaID(\\d+)/adicionar-etapa",
  //   component: lazy(() => import("../pages/step")),
  // },
];

const routes = [
  {
    path: "/criar-empresa",
    component: lazy(() =>
      import("../containers/onboarding/CompanyCreationContainer")
    ),
    fullPage: true,
  },
  {
    path: "/selecionar-empresa",
    component: lazy(() =>
      import("../containers/onboarding/SelectCompanyContainer")
    ),
    fullPage: true,
  },
  {
    path: "/administracao/times",
    component: withRoleRoute(Teams, ["ADMIN"]),
  },
  {
    path: "/administracao/colaboradores",
    component: withRoleRoute(Members, ["ADMIN"]),
  },
  {
    path: "/administracao/configuracoes",
    component: withRoleRoute(CompanySettings, ["ADMIN"]),
  },

  // {
  //   path: "/administracao/pagamento",
  //   component: lazy(() => import("../views/settings/PaymentSettingsView")),
  // },
  // {
  //   path: "/administracao/plano",
  //   component: lazy(() => import("../views/settings/PlanSettingsView")),
  // },
  {
    path: "/administracao/ciclos",
    component: lazy(() => import("../views/settings/CyclesSettingsView")),
  },
  // {
  //   path: "/ideias/:ideaID(\\d+)/etapas",
  //   component: lazy(() => import("../pages/ideaSteps")),
  // },
  {
    path: "/objetivos",
    component: lazy(() => import("../pages/okrs")),
  },
  {
    path: "/workflows",
    component: lazy(() => import("../pages/workflows/list")),
  },
  // {
  //   path: "/calendario",
  //   component: lazy(() => import("../pages/calendar")),
  // },
  // {
  //   path: "/quadro",
  //   component: lazy(() => import("../pages/map")),
  // },
  // {
  //   path: "/lista",
  //   component: lazy(() => import("../views/lists/Strategy")),
  // },
  {
    path: "/focos-da-semana",
    component: lazy(() => import("../pages/weekly-priorities/list")),
  },
  {
    path: "/acoes",
    component: lazy(() => import("../pages/check-ins/list")),
  },
  {
    path: "/editar-perfil",
    component: lazy(() => import("../pages/settings/UserSettingsContainer")),
  },
  {
    path: "/",
    component: lazy(() => import("../pages/myDashboard")),
    isExact: true,
  },
];

export default routes;
