import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Divider, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { useDispatch, useSelector } from "react-redux";

import Card from "../../components/common/Card";
import Icon from "../../components/common/Icon";
import { WeeklyPriorities } from "../../components/weekly-priorities/WeeklyPriorities";
import PersonalCheckins from "../../components/checkins/PersonalCheckins";
import PendingCheckins from "../../components/checkins/PendingCheckins";
import Activities from "../../components/checkins/Activities";
import { useUser } from "../../context/UserContext";
import { get } from "../../services/api";
import { fetchCheckins } from "../../redux/checkins/actions";
import { checkinsExactDateFilteredSelector } from "../../redux/checkins/selectors";
import { useFilter } from "../../context/FilterContext";
import { fetchOcurrences } from "../../redux/workflows/actions";
import { ocurrencesFilteredSelector } from "../../redux/workflows/selectors";

export default function DashboardView({ date }) {
  const { data: dataSource } = useSWR(
    `/weekly_priorities/get_user_priorities`,
    get,
    {
      revalidateOnFocus: false,
    }
  );
  let initialValues = {
    organization: false,
    personal: false,
    teams: [],
  };
  if (dataSource && dataSource.length > 0) {
    if (dataSource[0].type === "MEMBER") {
      initialValues.personal = true;
    } else if (dataSource[0].type === "TEAM")
      initialValues.teams = [dataSource[0].team];
    else if (dataSource[0].type === "MEMBER") initialValues.organization = true;
  }
  return (
    <div className="m-y:2">
      <Row gutter={20}>
        <Col span={16}>
          <Space size={20} direction="vertical" className="w:full">
            <MyCheckins date={date} />
            <Activities />
          </Space>
        </Col>
        <Col span={8}>
          <Space direction="vertical" className="w:full" size={20}>
            <WeeklyPriorities
              date={date}
              initialFilter={initialValues}
              dataSource={dataSource}
            />
            <PendingCheckins />
          </Space>
        </Col>
      </Row>
    </div>
  );
}

export function MyCheckins() {
  const { t } = useTranslation();
  const { date } = useFilter();
  const dateFormatted = date?.format("YYYY-MM-DD");
  const [user] = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchCheckins({
        author: user.member_id,
        date: dateFormatted,
      })
    );
  }, [dispatch, user.member_id, dateFormatted]);

  const plannedCheckins = useSelector(
    checkinsExactDateFilteredSelector({
      date: dateFormatted,
      author: user.member_id,
      finished: false,
    })
  );

  const finishedCheckins = useSelector(
    checkinsExactDateFilteredSelector({
      date: dateFormatted,
      author: user.member_id,
      finished: true,
    })
  );

  useEffect(() => {
    dispatch(
      fetchOcurrences({
        member: user.member_id,
        date: dateFormatted,
      })
    );
  }, [dispatch, user.member_id, dateFormatted]);

  const pendingWorkflows = useSelector(
    ocurrencesFilteredSelector({
      completed: false,
    })
  );

  const completedWorkflows = useSelector(
    ocurrencesFilteredSelector({
      completed: true,
    })
  );

  return (
    <Card>
      <div className="d:f j-c:s-b a-i:c m-b:1">
        <div className="text-darkviolet-sec d:f a-i:c">
          <h4 className="text-darkviolet-sec m-b:0 m-r:.5">
            <Icon type="checkin" className="m-r:.8" />
            {t("actions.label")}
          </h4>
          <span className="m-t:.5">
            • {t("today.label").concat(", ", date?.format("DD/MMM"))}
          </span>
        </div>
        <Link
          to={`/acoes/?time=${user.company.teams[0]?.id}`}
          className="text-primary"
        >
          {t("dashboard.actions.card.label_1")}
          <Icon type="chevronRight" />
        </Link>
      </div>
      <>
        <h6 className="text-mediumgray t-t:u m-b:.8">
          {t("dashboard.actions.planned-activies.label")}
        </h6>
        <PersonalCheckins
          data={plannedCheckins}
          createFields={{
            date: dateFormatted,
            finished: false,
          }}
          workflows={pendingWorkflows}
        />
      </>
      {(completedWorkflows?.length > 0 || finishedCheckins?.length > 0) && (
        <>
          <Divider className="m-b:.8" />
          <>
            <h6 className="text-mediumgray t-t:u m-b:.8">
              {t("dashboard.actions.performed-activies.label")}
            </h6>
            <PersonalCheckins
              data={finishedCheckins}
              workflows={completedWorkflows}
            />
          </>
        </>
      )}
    </Card>
  );
}
