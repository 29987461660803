// @flow
import {
  FETCH_IDEAS,
  FETCH_IDEAS_SUCCESS,
  FETCH_IDEAS_FAILED,
  FETCH_IDEA,
  PATCH_IDEA,
  PATCH_IDEA_SUCCESS,
  PATCH_IDEA_FAILED,
  CREATE_IDEA,
  DELETE_IDEA,
  DELETE_IDEA_SUCCESS,
  DELETE_IDEA_FAILED,
} from "./constants";
import { mergeDistinct } from "../../utils";

const INIT_STATE = {
  ideas: [],
  loaded: false,
  loading: false,
};

const Ideas = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_IDEAS:
      return { ...state, loading: true, loaded: false };
    case FETCH_IDEAS_SUCCESS:
      return {
        ...state,
        ideas: mergeDistinct(state.ideas, action.payload, "id"),
        loading: false,
        error: null,
        loaded: true,
      };
    case FETCH_IDEAS_FAILED:
      return { ...state, error: action.payload, loading: false, loaded: true };

    case FETCH_IDEA:
      return { ...state, loading: true, loaded: false };

    case PATCH_IDEA:
      return { ...state, loading: true };
    case PATCH_IDEA_SUCCESS:
      const ideas = [...state.ideas];
      const index = ideas.findIndex(({ id }) => id == action.payload.id);
      ideas[index] = action.payload;
      return { ...state, ideas };
    case PATCH_IDEA_FAILED:
      return { ...state };

    case CREATE_IDEA:
      return { ...state, loading: true };

    case DELETE_IDEA:
      return { ...state, loading: true, loaded: false };
    case DELETE_IDEA_SUCCESS:
      return {
        ...state,
        ideas: state.ideas.filter(({ id }) => id != action.payload),
        loading: false,
        error: null,
      };
    case DELETE_IDEA_FAILED:
      return { ...state, error: action.payload, loading: false, loaded: true };

    default:
      return { ...state };
  }
};

export default Ideas;
