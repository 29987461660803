import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Menu, Layout } from "antd";

import { UserContext } from "../../../context/UserContext";

import UserAvatar from "../UserAvatar/index";
import Icon from "../Icon";
import { StyledSider } from "./styles";
import Button from "../Button";

export default function Sidebar() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const { user } = useContext(UserContext);
  const location = useLocation();

  const links = [
    [t("home.label"), "/", "home", ({ url }) => url === "/"],
    [t("objectives.label"), "/objetivos", "okrs"],
    [t("weeklypriorities.label"), "/focos-da-semana", "focus"],
    [t("actions.label"), "/acoes", "checkin"],
    [t("workflows.label"), "/workflows", "workflows"],
  ];
  if (user.role === "ADMIN") {
    links.push([t("teamsAndMembers.label"), "/administracao/times", "team"]);
    links.push([
      t("settings.label"),
      "/administracao/configuracoes",
      "settings",
    ]);
  }

  return (
    <>
      <StyledSider
        collapsed={!expanded}
        collapsedWidth={75}
        theme="light"
        width={200}
      >
        <div className={`d:f ${!expanded ? "j-c:c" : "j-c:f-s"} m-y:.5`}>
          <Button type="transparent" onClick={() => setExpanded(!expanded)}>
            <img
              width={!expanded ? 30 : 80}
              src={`/logo${expanded ? "" : "_basic"}.png`}
              className="logo"
              alt="feito logo"
            />
          </Button>
        </div>
        <Menu mode="inline" selectedKeys={[location.pathname]}>
          {links.map((link, index) => (
            <Menu.Item
              className="d:f w:1"
              key={link[1]}
              icon={<Icon type={link[2]} />}
            >
              <div className="d:f content">
                <Link to={link[1]}>{link[0]}</Link>
                {location.pathname === link[1] && (
                  <div className="m-l:.5 d:f j-c:c a-i:c selectedItem">
                    <Icon type="chevronRightFilled" />
                  </div>
                )}
              </div>
            </Menu.Item>
          ))}
        </Menu>
        <Menu mode="inline" className="pos:a bottom:2 avatar">
          <Menu.Item className="j-c:c d:f m-b:1" title={user.first_name}>
            <UserAvatar
              size={34}
              src={user.profile_image}
              userCompanies={user.num_companies}
            />
          </Menu.Item>
        </Menu>
      </StyledSider>
    </>
  );
}
