import styled from "styled-components";
import { Layout } from "antd";

const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  height: 100vh;
  position: sticky;
  top: 0vh;

  font-weight: 500;
  border-right: 1px solid var(--neutralblue) !important;

  .ant-menu-inline-collapsed {
    width: 100%;
    border-right: none;
  }
  .ant-menu-inline {
    border-right: none;
  }

  /* Estilos para o item atualmente selecioando do menu */
  .ant-menu .ant-menu-item-selected {
    :not(:only-child) {
      background: rgba(49, 60, 109, 0.1);
    }
    :only-child {
      background: none;
    }
  }

  .ant-menu-item a {
    color: #1d2644;
  }
  /* Remover borda direita do item do menu que estiver selecionado */
  .ant-menu-item::after {
    border-right: none;
  }

  /* Mudar cor e tamanho da fonte dos ícones da sidebar */
  .ant-menu-item i {
    color: #828282;
    font-size: 1.2em;
  }

  /* Estilos da setinha à direita do items elecioando do menu */
  .selectedItem {
    background-color: #a6b3f7;
    i {
      color: #313c6d !important;
      font-size: 0.8em !important;
    }
    width: 1.5em;
  }

  .ant-menu-inline {
    .ant-menu-item-selected {
      padding-right: 0;
      border-right: none;
      .content {
        justify-content: space-between;
      }
      a {
        margin-right: 1em;
      }
      span {
        width: 100%;
      }
    }
  }

  /* Muda o display e largura de todos os itens do menu exceto o user avatar */
  .ant-menu-item:not(:only-child) {
    display: flex;
    width: 100%;
  }

  /* Muda display largura e padding do menu item do user avatar  */
  .ant-menu-item:only-child {
    display: flex;
    width: 100%;
    padding: 0px !important;
  }
`;

{
  /* <CollapseButton
  className={expanded ? "button-expanded" : ""}
  onClick={() => setExpanded(!expanded)}
>
  <Icon type={expanded ? "navCollapse" : "navExpand"} />
</CollapseButton>; */
}

const CollapseButton = styled.button`
  background-color: var(--neutralblue) !important;
  /* color: var(--primary) !important; */
  position: absolute;
  top: 20px;
  right: -26px;
  padding: 10px 4px !important;
  border-radius: 0 10px 10px 0 !important;
  border: none;
  &.button-expanded {
    border-radius: 10px 0 0 10px !important;
    right: 0;
    box-shadow: 0px -1px 25px rgba(0, 0, 0, 0.1);
  }
`;
