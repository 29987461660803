import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Select } from "antd";
import moment from "moment";

import { requiredRule } from "../../utils/form";

import { MyCheckins } from "../Home/DashboardView";
import CreateNewPriority from "../../components/onboarding/PriorityCard";

export default function Onboarding({
  user,
  step,
  form,
  options,
  handleSubmit,
}) {
  const { t } = useTranslation();

  const steps = [
    <>
      <Form.Item
        label={t("onboarding.step_2.label_1")}
        name="company"
        rules={[requiredRule]}
      >
        <Input
          size="large"
          placeholder={t("onboarding.step_2.label_3")}
          defaultValue=""
        />
      </Form.Item>
      <span>{t("onboarding.step_2.label_2")}</span>
    </>,
    <>
      {/*caso o usuario esteja criando uma nova empresa utiliza os valores predefinidos(options em onboarding) para a criação do primeiro time */}
      <Form.Item
        rules={[requiredRule]}
        name="team"
        label={t("onboarding.step_3.label_1")}
      >
        <Select size="large" options={options} />
      </Form.Item>
      <Form.Item dependencies={["team"]}>
        {() => {
          return form.getFieldValue("team") ===
            t("onboarding.create_teams.label_7") ? (
            <Form.Item
              rules={[requiredRule]}
              name="otherName"
              label={t("onboarding.step_3.label_2")}
            >
              <Input
                placeholder={t("onboarding.step_3.label_3")}
                size="large"
                onFinish={(value) => form.setFieldsValue({ team: value })}
              />
            </Form.Item>
          ) : (
            <> </>
          );
        }}
      </Form.Item>
    </>,
    <>
      {/* card para a criação do foco da semana*/}
      <Form.Item label={t("onboarding.step_5.label_1")} className="t-a:l">
        <CreateNewPriority user={user} />
      </Form.Item>
    </>,
    <>
      {/* card modificado para a criação de checkins*/}
      <Form.Item label={t("onboarding.step_6.label_1")} className="t-a:l">
        <MyCheckins date={moment()} reduced={true} />
      </Form.Item>
    </>,
  ];

  return (
    <Form
      layout="vertical"
      preserve
      form={form}
      onFinish={(e) => {
        if (step === 1) handleSubmit(e);
      }}
    >
      {steps[step]}
    </Form>
  );
}
