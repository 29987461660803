import React from "react";
import axios from "axios";
import amplitude from "amplitude-js";
import { AmplitudeProvider, LogOnMount } from "@amplitude/react-amplitude";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { ConnectedRouter } from "connected-react-router";
import ptBr from "antd/lib/locale/pt_BR";

import "./styles/antd-override.less";
import "./styles/index.sass";

import MainRouter from "./routes/MainRouter";
import { configureStore, history } from "./redux/store";

const AMPLITUDE_KEY = "5545a1b7cf38da9d090cd08e1ba816ad";

export const store = configureStore();

class App extends React.Component {
  constructor(props) {
    super(props);
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.xsrfHeaderName = "X-CSRFToken";
    axios.defaults.withCredentials = true;
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
  }
  render() {
    return (
      <ConfigProvider locale={ptBr}>
        <AmplitudeProvider
          amplitudeInstance={amplitude.getInstance()}
          apiKey={AMPLITUDE_KEY}
        >
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <MainRouter />
            </ConnectedRouter>
          </Provider>
        </AmplitudeProvider>
      </ConfigProvider>
    );
  }
}

export default App;
