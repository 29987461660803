import produce from "immer";

import * as constants from "./constants";

const initialState = {
  entities: {
    workflows: {},
    ocurrences: {},
  },
};

const Workflows = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case constants.LOAD_ALL_PARAM_SUCCESS:
        draft.entities.workflows = {
          ...draft.entities.workflows,
          ...action.payload.reduce((nextState, item) => {
            if (item.workflows !== undefined) {
              item.workflows.forEach((workflow) => {
                const date = item.date;
                const weekdays = nextState[workflow.id]?.weekdays || {};
                nextState[workflow.id] = {
                  weekdays: { date, ...weekdays },
                  ...workflow,
                };
              });
            } else {
              nextState[item.id] = { ...item };
            }
            return nextState;
          }, {}),
        };
        break;

      case constants.LOAD_OCURRENCES_PARAM_SUCCESS:
        draft.entities.ocurrences = {
          ...draft.entities.ocurrences,
          ...action.payload.reduce((nextState, ocurrence) => {
            nextState[ocurrence.id] = { ...ocurrence };
            return nextState;
          }, {}),
        };
        break;

      case constants.LOAD_ITEM_SUCCESS:
        draft.entities.workflows[action.payload.id] = action.payload;
        break;

      case constants.CREATE_ITEM_SUCCESS:
        draft.entities.workflows[action.payload.id] = action.payload;
        // message.success("Workflow criado com sucesso!");
        break;

      case constants.DELETE_ITEM_SUCCESS:
        delete draft.entities.workflows[action.payload];
        // message.success("Workflow excluido com sucesso!");

        break;

      default:
        return state;
    }
  });

export default Workflows;

// displayGenericError();
