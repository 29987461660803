import React from "react";
import classNames from "classnames";

export default function Invalid({ value, className, ...props }) {
  if (!value) return false;
  const text = (value || []).join(", ");
  return (
    <small className={classNames("text-danger", className)} {...props}>
      {text}
    </small>
  );
}
