import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form } from "antd";

import Header from "../../components/onboarding/OnboardingHeader";
import axios from "axios";
import { useUser } from "../../context/UserContext";
import StepButtons from "../../components/common/Button/StepButton";
import { displayGenericError } from "../../utils/messages";
import CompanyOnboarding from "../../views/onboarding/CompanyOnboarding";
import { StyledDiv } from "./StyledPage";
import DefaultTeams from "../../lib/DEFAULT_TEAMS";

export default function Onboarding() {
  const [user, updateUser] = useUser();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [step, setStep] = useState(0);
  const [id, setId] = useState();

  //opções predifinidas para criação de times caso o usuario esteja criando uma nova empresa
  const options = DefaultTeams();
  const stepsFields = [["company"], ["team", "otherName"], [], []];
  //verifica a etapa que o usuario se encontra, valida o item, e caso seja a ultima etapa, finaliza o onboarding
  const handleContinue = async () => {
    if (step === 3) {
      try {
        await axios.patch(`/companies/members/${id}/`, {
          onboarding_finished: true,
        });
        updateUser();
      } catch {
        displayGenericError();
      }
    } else {
      try {
        await form.validateFields(stepsFields[step]);
        if (step === 1) {
          form.submit();
          return;
        }
        setStep(step + 1);
      } catch {
        displayGenericError();
      }
    }
  };
  const handleBack = () => setStep(step - 1);
  //função chamada ao finalizar onboarding,declara um array no qual é preenchido com requisições para a api
  const handleSubmit = () => {
    const values = form.getFieldsValue(true);
    axios.post(`/companies/`, { name: values.company }).then(({ data }) => {
      setId(data.members[0].id);
      axios.post(`/teams/`, {
        name:
          values.team === t("onboarding.create_teams.label_7")
            ? values.otherName
            : values.team,
        members: [data.members[0].id],
        leader: data.members[0].id,
      });
    });
    updateUser();
    setStep(step + 1);
  };

  let headerKey = undefined;
  if (step === 2) headerKey = 1;
  else if (step === 3) headerKey = 2;

  return (
    <StyledDiv>
      <Header headerKey={headerKey} />
      <div className="m:a stepDiv">
        <div className="t-a:c">
          {/* form com cada etapa do onboarding  */}
          <CompanyOnboarding
            form={form}
            user={id}
            step={step}
            handleContinue={handleContinue}
            options={options}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
      {/*botões que controlam as etapas do onboarding  */}
      <StepButtons
        handleBack={handleBack}
        handleContinue={handleContinue}
        disableBack={[0, 2].includes(step)}
      />
    </StyledDiv>
  );
}
