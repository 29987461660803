import React from "react";
import ReactDOM from "react-dom";
import { init as SentryInit } from "@sentry/browser";

import * as serviceWorker from "./serviceWorker";
import App from "./App";
import LocaleProvider from "./providers/Locale";

if (process.env.NODE_ENV !== "development")
  SentryInit({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });

ReactDOM.render(
  <React.StrictMode>
    <LocaleProvider>
      <App />
    </LocaleProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
