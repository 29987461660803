import React from "react";
import { Avatar, Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import Icon from "../Icon";

export default function UserAvatar({ ...props }) {
  return (
    <Dropdown
      className="cur:p"
      trigger="click"
      placement="topLeft"
      overlay={
        <Menu>
          <Menu.Item key="profile">
            <a href="/editar-perfil/">
              <Icon className="m-r:.5" type="user" />
              Perfil
            </a>
          </Menu.Item>
          {props.userCompanies > 1 && (
            <Menu.Item
              icon={<Icon type="switchCompany" className="m-r:.5" />}
              key="switchCompany"
            >
              <Link to="/selecionar-empresa/">Trocar empresa</Link>
            </Menu.Item>
          )}
          <Menu.Item key="logout">
            <a href={process.env.REACT_APP_AUTH_URL.concat("/logout/")}>
              <Icon className="m-r:.5" type="logout" />
              Logout
            </a>
          </Menu.Item>
        </Menu>
      }
    >
      <Avatar {...props} />
    </Dropdown>
  );
}
